<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">좌석알림 설정</div>
        <div class="description">
          · 좌석알림 상세설정을 관리합니다. <br />
          · 페이지 하단 ‘알림설정 추가’를 클릭하면, 사업장 별로
          다르게 설정할 수 있습니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info" v-for="(setting, index) in fetchedAlarmSettingList" :key="setting.id">
            <div class="part_title between">
              <span>알림설정 {{ index + 1 }}</span>              
              <button v-if="index > 0" @click="removeSetting(index)">
                삭제
              </button>
            </div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>예약완료 알림</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="td_toggle" >
                      <input
                        type="checkbox"
                        :id="'toggleresCompleteYn' + setting.id"
                        class="toggle"
                        hidden
                        :checked="setting.resCompleteYn === 'Y'"
                        @change="toggleActiveStatus(setting, 1)"
                      /> 
                      <label :for="'toggleresCompleteYn' + setting.id" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>예약취소 알림</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="td_toggle">
                      <input
                        type="checkbox"
                        :id="'toggleresCancelYn' + setting.id"
                        class="toggle"
                        hidden
                        :checked="setting.resCancelYn === 'Y'"
                        @change="toggleActiveStatus(setting, 2)"
                      /> 
                      <label :for="'toggleresCancelYn' + setting.id" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>체크인 알림</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="td_toggle">
                      <input
                        type="checkbox"
                        :id="'togglecheckInYn' + setting.id"
                        class="toggle"
                        hidden
                        :checked="setting.checkInYn === 'Y'"
                        @change="toggleActiveStatus(setting, 3)"
                      /> 
                      <label :for="'togglecheckInYn' + setting.id" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>연장완료 알림</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="td_toggle">
                      <input
                        type="checkbox"
                        :id="'toggledelayCompleteYn' + setting.id"
                        class="toggle"
                        hidden
                        :checked="setting.delayCompleteYn === 'Y'"
                        @change="toggleActiveStatus(setting, 4)"
                      /> 
                      <label :for="'toggledelayCompleteYn' + setting.id" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>종료 전 알림</th>
                <td class="td_address">
                  <div class="td_column">
                    <div class="td_toggle">
                      <input
                        type="checkbox"
                        :id="'toggleendBeforeYn' + setting.id"
                        class="toggle"
                        hidden
                        :checked="setting.endBeforeYn === 'Y'"
                        @change="toggleActiveStatus(setting, 5)"
                      /> 
                      <label :for="'toggleendBeforeYn' + setting.id" class="toggleSwitch">
                        <span class="toggleButton"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="userType === 'C'">
                <th>적용대상</th>
                <td>
                  <div class="inner_boxs workplace">
                    <div class="inner_box_column">
                      <div class="checks innerbox_title">
                        <input
                          type="checkbox"
                          :id="'Workplace' + setting.id"
                          v-model="setting.allLocations"
                          @change="checkAllLocations(index)"
                        />
                        <label :for="'Workplace' + setting.id">
                          모든 사업장</label
                        >
                      </div>
                      <div v-show="!setting.allLocations" class="tagbox">
                        <button @click="showModal(index)">+사업장추가</button>
                        <ul class="attendee_add group_choice tags">
                          <li v-for="(location, locIndex) in setting.workplaceNames" :key="locIndex">
                            <div>
                              <span>{{ location }}</span>
                            </div>
                            <a
                              @click="removeLocation(index, locIndex, setting.workplaceIds[locIndex])"
                              style="cursor: pointer"
                              >⨉</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" v-if="userType === 'C'" @click.prevent="addSetting">+알림설정추가</a>
            <a href="###" class="on" @click.prevent="saveSettings">저장</a
            >
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 사업장 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">사업장 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_200"
                      placeholder="사업장명을 입력해주세요"
                      v-model="SearchKey"
                      @keyup.enter="searchWorkplace"
                    />
                    <button type="button" @click="searchWorkplace">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 10%" />
              <col />
              <col style="width: 19%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @change="checkSelectAll"
                  />
                </th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workplace in paginatedWorkplaces"
                :key="workplace.workplaceId"
                :class="{ disabled: isWorkplaceDisabled(workplace) }"
              >
                <td>
                  <input
                    type="checkbox"
                    v-model="workplace.selected"
                    :disabled="isWorkplaceDisabled(workplace)"
                  />
                </td>
                <td>{{ workplace.workplaceName }}</td>
                <td>
                  <button
                    @click="selectWorkplace(workplace)"
                    class="keep"
                    :disabled="isWorkplaceDisabled(workplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="confirmSelection">
          선택완료
        </button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <!-- //사업장 선택 modal -->
</template>
<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 


export default {
  name: "SettingFreeSeatTypeAlarmSetting",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fetchedAlarmSettingList: [],
      settings: [], // API 로부터 가져온 데이터 설정묶음
      workplaceId: "", // 사업장 아이디 선택값
      isModalVisible: false, // 모달 표시 상태
      workplaces: [], // 모달에 표시할 사업장 목록
      selectedSettingIndex: null, // 현재 선택된 설정의 인덱스
      selectAll: false, // 전체 선택 체크박스 상태
      totalWorkplaces: 0, // 총 사업장 수
      SearchKey: "", // 검색 키워드
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수,
      totalFreeseatWorkplaceCnt: 0, //  현재 회사의 자율좌석 사용설정된 총 사업장 수,
      unUsingWorkplaceIds: []
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalWorkplaces / this.itemsPerPage);
    },
    // 현재 페이지에 해당하는 사업장 목록을 반환
    paginatedWorkplaces() {
      if (!Array.isArray(this.workplaces)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log("Paginated Workplaces: ", this.workplaces.slice(start, end));

      return this.workplaces.slice(start, end);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    isAnyAllLocationsChecked() {
      return this.fetchedAlarmSettingList.some((setting) => setting.allLocations);
    },
  },
  methods: {
    async fetchAlarmSettingData(){
      try {
        const response = await apiClient.post("/api/Freeseat/freeseat-alarm-setting", {
          CompanyId: localStorage.getItem("companyid"),
          WorkplaceId: localStorage.getItem("workplaceid"),
          SearchUserType: localStorage.getItem("usertype")
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(response.data);
          if (data.data.length === 0) {
            this.fetchedAlarmSettingList = [this.createDefaultSetting()];
          } else {
            this.fetchedAlarmSettingList = data.data.map((item) => ({
              id: Date.now() + Math.random(),
              companyId: item.companyId,
              workplaceIds: item.workplaceId.split("^"),
              workplaceNames: item.workplaceName.split("^"),
              resCompleteYn: item.resCompleteYn,
              resCancelYn: item.resCancelYn,
              checkInYn: item.checkInYn,
              delayCompleteYn: item.delayCompleteYn,
              endBeforeYn: item.endBeforeYn,
              allLocations: false,
            }));
          }    
        }
      } catch (error) {
        console.error("Error fetching alarm setting data:", error);
      }
    },
    async toggleActiveStatus(setting, index) {
      try {
        const settingValueMap = {
          1: "resCompleteYn",
          2: "resCancelYn",
          3: "checkInYn",
          4: "delayCompleteYn",
          5: "endBeforeYn",
        };
        const property = settingValueMap[index];

        if (!property) {
          console.error("Error toggleActiveStatus Invalid Index:", index);
          throw new Error(`Invalid index: ${index}`);
        }

        const newValue = setting[property] === "Y" ? "N" : "Y";
        setting[property] = newValue;
      } catch (error) {
        console.error("Error toggleActiveStatus:", error);
      }
    },
    async loadWorkplaces() {
      try {
        const searchParam = {
          SearchType: "workplaceName",
          SearchKey: this.SearchKey,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: localStorage.getItem("companyid"),
        };
        const response = await apiClient.post(
          "/api/Workplace/freeseat-using-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          this.totalWorkplaces = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    },
    async getTotalFreeseatUsingWorkplace() {
      try {
        const searchParam = {
          CompanyId: localStorage.getItem("companyid"),
        };
        const response = await apiClient.post(
          "/api/Freeseat/freeseat-using-workplace-count",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.totalFreeseatWorkplaceCnt = data.meta.totalCount;
        }
      } catch (error) {
        console.error("Error freeseat using workplace count", error);
      }
    },
    createDefaultSetting() {
      return {
        id: Date.now() + Math.random(),
        companyId: localStorage.getItem("companyid"),
        workplaceIds: [],
        workplaceNames: [],
        resCompleteYn: "Y",
        resCancelYn: "Y",
        checkInYn: "Y",
        delayCompleteYn: "Y",
        endBeforeYn: "Y",
        allLocations: false
      };
    },
    addSetting() {
      if (this.totalFreeseatWorkplaceCnt === 0) {
		    this.toast.error("현재 자율좌석을 사용하도록 설정된 사업장이 없어 추가할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } else {      
        this.fetchedAlarmSettingList.push(this.createDefaultSetting());
      }
    },
    removeSetting(index) {
      if (this.fetchedAlarmSettingList.length === 1) {
		    this.toast.error("최소 하나의 알림설정은 필요합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } else {
        this.fetchedAlarmSettingList.splice(index, 1);
      }
    },
    showModal(index) {
      if (this.totalFreeseatWorkplaceCnt > 0) {
        this.selectedSettingIndex = index;
        this.updateWorkplacesDisabledState();
        this.isModalVisible = true;
      } else {
        this.toast.error("현재 자율좌석을 사용하도록 설정된 사업장이 없어 추가할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },    
    hideModal() {
      this.isModalVisible = false;
      this.workplaces.forEach((workplace) => {
        delete workplace.disabled; // 비활성화 상태 제거
      });
    },
    updateWorkplacesDisabledState() {
      const disabledWorkplaceIds = new Set(
        this.fetchedAlarmSettingList.flatMap((setting) => setting.workplaceIds)
      );

      this.workplaces.forEach((workplace) => {
        workplace.disabled = disabledWorkplaceIds.has(workplace.workplaceId);
      });
    },    
    checkSelectAll() {
      const selectAllState = this.selectAll;
      this.workplaces.forEach((workplace) => {
        workplace.selected = selectAllState;
      });
    },
    checkAllLocations(index) {
      if (this.fetchedAlarmSettingList[index].allLocations) {
        const confirmed = confirm(
          "모든 사업장이 선택되면 다른 알림설정은 초기화됩니다. 진행하시겠습니까?"
        );
        if (confirmed) {
          this.fetchedAlarmSettingList = this.fetchedAlarmSettingList.filter((_, i) => i === index);
        } else {
          // 확인을 누르지 않았을 경우 체크를 해제합니다.
          this.fetchedAlarmSettingList[index].allLocations = false;
        }
      } else {
        this.fetchedAlarmSettingList[index].workplaceIds = [];
        this.fetchedAlarmSettingList[index].workplaceNames = [];
      }
    },    
    isWorkplaceDisabled(workplace) {
      return (
        workplace.disabled ||
        this.fetchedAlarmSettingList.some((setting) =>
          setting.workplaceIds.includes(workplace.workplaceId)
        )
      );
    },    
    removeLocation(settingIndex, locIndex, workplaceId) {
      const confirmed = confirm("삭제하시겠습니까?");
      if (confirmed) {
        this.fetchedAlarmSettingList[settingIndex].workplaceIds.splice(locIndex, 1);
        this.fetchedAlarmSettingList[settingIndex].workplaceNames.splice(locIndex, 1);
        this.unUsingWorkplaceIds.push(workplaceId);
      }
    },
    selectWorkplace(workplace) {
      const setting = this.fetchedAlarmSettingList[this.selectedSettingIndex];

      if (setting.workplaceIds.includes(workplace.workplaceId)) {
		    this.toast.error("이미 알림설정 선택된 사업장입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return; 
      }

      const unUsingIndex = this.unUsingWorkplaceIds.indexOf(workplace.workplaceId);
      if (unUsingIndex !== -1) {
        this.unUsingWorkplaceIds.splice(unUsingIndex, 1);
      }

      setting.workplaceIds.push(workplace.workplaceId);
      setting.workplaceNames.push(workplace.workplaceName);

      this.hideModal();
    },
    confirmSelection() {
      const selectedWorkplaces = this.workplaces.filter(
        (workplace) => workplace.selected
      );
      if (selectedWorkplaces.length === 0) {
		    this.toast.error("사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      selectedWorkplaces.forEach((workplace) => {
        this.selectWorkplace(workplace);
      });

      this.hideModal();
    },
    
    async saveSettings() {
      // 빈 설정 확인
      const emptySettings = this.fetchedAlarmSettingList.filter(
        (setting) => !setting.allLocations && setting.workplaceIds.length === 0
      );

      // 빈 설정이 있는 경우 경고 메시지 표시
      if (emptySettings.length > 0) {		
		    this.toast.error("사업장을 선택해주시기 바랍니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      const payload = this.fetchedAlarmSettingList
        .filter(
          (setting) => setting.allLocations || setting.workplaceIds.length > 0
        ) // 빈 설정을 제외
        .map((setting) => ({
          companyId: setting.companyId,
          workplaceIds: setting.allLocations
            ? this.workplaces
                .map((workplace) => workplace.workplaceId)
                .join("^") // 모든 사업장 ID를 추가
            : setting.workplaceIds.join("^"),
          resCompleteYn: setting.resCompleteYn,
          resCancelYn: setting.resCancelYn,
          checkInYn: setting.checkInYn,
          delayCompleteYn: setting.delayCompleteYn,
          endBeforeYn: setting.endBeforeYn
        }));

      console.log(payload);

      try {
        const savePromises = payload.map(async (data) => {
          const response = await apiClient.post(
            "/api/Freeseat/freeseat-alarm-setting-save",
            {
              CompanyId: data.companyId,
              WorkplaceId: data.workplaceIds,
              resCompleteYn: data.resCompleteYn,
              resCancelYn: data.resCancelYn,
              checkInYn: data.checkInYn,
              delayCompleteYn: data.delayCompleteYn,
              endBeforeYn: data.endBeforeYn,
              LoginUserId: localStorage.getItem("userid"),
            }
          );

          if (response.status !== 200) {
            throw new Error(
              "Error saving settings for freeseat alarm: " + data.workplaceIds
            );
          }

          return response.data;
        });

        await Promise.all(savePromises);

        const additionalPromises = this.unUsingWorkplaceIds.map(async (workplaceId) => {
        try {
            const response = await apiClient.post(
              "/api/Freeseat/freeseat-alarm-setting-useyn-update",
              {
                LoginUserId: localStorage.getItem("userid"),
                WorkplaceId: workplaceId,
              }
            );

            if (response.status !== 200) {
              throw new Error(`Error updating useYn for workplaceId: ${workplaceId}`);
            }
          } catch (error) {
            throw new Error(`Error updating useYn for workplaceId: ${workplaceId} - ${error.message}`);
          }
        });
        
        await Promise.all(additionalPromises);

		    this.toast.success("좌석알림 설정 정보가 성공적으로 저장되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      } catch (error) {
        console.error("Error saving freeseat alarm setting data:", error);	
		    this.toast.error("좌석알림 설정 정보 저장 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
  },
  mounted() {
    this.fetchAlarmSettingData();
    this.loadWorkplaces();
    this.getTotalFreeseatUsingWorkplace();
  }
};
</script>

<style scoped>
</style>
