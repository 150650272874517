// 로그인 페이지
import LoginPage from "@/views/LoginPage.vue";
// 내 정보 관련 페이지
import MyInfoManagement from "@/views/myinfo/management.vue";
import MyInfoView from "@/views/myinfo/view.vue";
// 회사관리 관련 페이지
import CompanyManagement from "@/views/company/management.vue";
import CompanyList from "@/views/company/list.vue";
import CompanyAdd from "@/views/company/add.vue";
import CompanyView from "@/views/company/view.vue";
// 사업장관리 관련 페이지
import WorkplaceManagement from "@/views/workplace/management.vue";
import WorkplaceList from "@/views/workplace/list.vue";
import WorkplaceAdd from "@/views/workplace/add.vue";
import Workplaceview from "@/views/workplace/view.vue";
// 직원관리 관련 페이지
import EmployeeManagement from "@/views/employee/management.vue";
import EmployeeList from "@/views/employee/list.vue";
import EmployeeAdd from "@/views/employee/add.vue";
import Employeeview from "@/views/employee/view.vue";
import DeptList from "@/views/employee/dept_list.vue";
import RequestJoinList from "@/views/employee/request_join_list.vue";
import WithDrawalList from "@/views/employee/withdraw_list.vue";
// 상품관리 관련 페이지
// 상품분류 목록
import ProductManagement from "@/views/product/management.vue";
import ProductCategoryList from "@/views/product/category/list.vue";
import ProductCategoryAdd from "@/views/product/category/add.vue";
import ProductCategoryView from "@/views/product/category/view.vue";
// 회의실 목록
import ProductMeetingroomList from "@/views/product/meetingroom/list.vue";
import ProductMeetingroomAdd from "@/views/product/meetingroom/add.vue";
import ProductMeetingroomView from "@/views/product/meetingroom/view.vue";
// 자율좌석 목록
import ProductFreeseatList from "@/views/product/freeseat/list.vue";
import ProductFreeseatAdd from "@/views/product/freeseat/add.vue";
import ProductFreeseatView from "@/views/product/freeseat/view.vue";
// 공용시설 목록
import ProductPublicroomList from "@/views/product/publicroom/list.vue";
import ProductPublicroomAdd from "@/views/product/publicroom/add.vue";
import ProductPublicroomView from "@/views/product/publicroom/view.vue";
// 예약관리 관련 페이지
import ResrvationManagement from "@/views/reservation/management.vue";
import ResrvationMeetingroomStatusList from "@/views/reservation/meetingroom/status/list.vue";
import ResrvationMeetingroomStatusview from "@/views/reservation/meetingroom/status/view.vue";
import ResrvationFreeseatList from "@/views/reservation/freeseat/status/list.vue";
import ResrvationFreeseatView from "@/views/reservation/freeseat/status/view.vue";
import ResrvationPublicroomStatusList from "@/views/reservation/publicroom/status/list.vue";
import ResrvationPublicroomStatusview from "@/views/reservation/publicroom/status/view.vue";
// 인증관리 관련 페이지
import CertificationManagement from "@/views/certification/management.vue";
import CertificationList from "@/views/certification/list.vue";
import Certificationview from "@/views/certification/view.vue";
// 설정 관련 페이지
import SettingManagement from "@/views/setting/management.vue";
import SettingMeetingroomAccessControl from "@/views/setting/meetingroom/access/control.vue";
import SettingNoticeList from "@/views/setting/notice/list.vue";
import SettingNoticeAdd from "@/views/setting/notice/add.vue";
import SettingNoticeView from "@/views/setting/notice/view.vue";
import SettingPrivacyList from "@/views/setting/privacy/list.vue";
import SettingPrivacyAdd from "@/views/setting/privacy/add.vue";
import SettingPrivacyView from "@/views/setting/privacy/view.vue";
import SettingTermsofuseList from "@/views/setting/termsofuse/list.vue";
import SettingTermsofuseAdd from "@/views/setting/termsofuse/add.vue";
import SettingTermsofuseView from "@/views/setting/termsofuse/view.vue";
import SettingUserJoinColumnList from "@/views/setting/user/join/column/list.vue";
import SettingUserJoinColumnListTwo from "@/views/setting/user/join/column/list_two.vue";
import SettingNoShowControl from "@/views/setting/etc/noshow/control.vue";
import SettingTabletList from "@/views/setting/etc/tablet/list.vue";
import SettingTabletView from "@/views/setting/etc/tablet/view.vue";
import SettingFreeSeatTypeList from "@/views/setting/freeseat/type/list.vue";
import SettingFreeSeatTypeAlarmSetting from "@/views/setting/freeseat/type/alarm_setting.vue";

import HeaderMenu from "@/components/Layout/HeaderMenu.vue";
import FooterMenu from "@/components/Layout/FooterMenu.vue";
import MyinfoLeftMenu from "@/components/Layout/Myinfo_Left.vue";
import CompanyLeftMenu from "@/components/Layout/Company_Left.vue";
import WorkplaceLeftMenu from "@/components/Layout/Workplace_Left.vue";
import EmployeeLeftMenu from "@/components/Layout/Employee_Left.vue";
import ProductLeftMenu from "@/components/Layout/Product_Left.vue";
import ReservationLeftMenu from "@/components/Layout/Reservation_Left.vue";
import CertificationLeftMenu from "@/components/Layout/Certification_Left.vue";
import SettingLeftMenu from "@/components/Layout/Setting_Left.vue";

import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store"; // Vuex 스토어를 정확한 경로로 가져옴

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/layout/header",
    name: "HeaderMenu",
    component: HeaderMenu,
  },
  {
    path: "/layout/footer",
    name: "FooterMenu",
    component: FooterMenu,
  },
  {
    path: "/layout/myinfo",
    name: "MyinfoLeftMenu",
    component: MyinfoLeftMenu,
  },
  {
    path: "/layout/company",
    name: "CompanyLeftMenu",
    component: CompanyLeftMenu,
  },
  {
    path: "/layout/workplace",
    name: "WorkplaceLeftMenu",
    component: WorkplaceLeftMenu,
  },
  {
    path: "/layout/employee",
    name: "EmployeeLeftMenu",
    component: EmployeeLeftMenu,
  },
  {
    path: "/layout/product",
    name: "ProductLeftMenu",
    component: ProductLeftMenu,
  },
  {
    path: "/layout/reservation",
    name: "ReservationLeftMenu",
    component: ReservationLeftMenu,
  },
  {
    path: "/layout/certification",
    name: "CertificationLeftMenu",
    component: CertificationLeftMenu,
  },
  {
    path: "/layout/setting",
    name: "SettingLeftMenu",
    component: SettingLeftMenu,
  },
  {
    path: "/myinfo/management",
    name: "MyInfoManagement",
    component: MyInfoManagement,
    children: [
      {
        path: "",
        name: "Default",
        component: MyInfoView, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/myinfo/view",
        name: "MyInfoView",
        component: MyInfoView,
      },
    ],
  },
  {
    path: "/company/management",
    name: "CompanyManagement",
    component: CompanyManagement,
    children: [
      {
        path: "",
        name: "CompanyDefault",
        component: CompanyList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/company/list",
        name: "CompanyList",
        component: CompanyList,
      },
      {
        path: "/company/add",
        name: "CompanyAdd",
        component: CompanyAdd,
      },
      {
        path: "/company/view/:id", // 해당 페이지로 이동할때 파라미터가 필요할 경우
        name: "CompanyView",
        component: CompanyView,
        props: true,
      },
    ],
  },
  {
    path: "/workplace/management",
    name: "WorkplaceManagement",
    component: WorkplaceManagement,
    children: [
      {
        path: "",
        name: "WorkplaceDefault",
        component: WorkplaceList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/workplace/list",
        name: "WorkplaceList",
        component: WorkplaceList,
      },
      {
        path: "/workplace/add",
        name: "WorkplaceAdd",
        component: WorkplaceAdd,
      },
      {
        path: "/workplace/view/:id", // 해당 페이지로 이동할때 파라미터가 필요할 경우
        name: "Workplaceview",
        component: Workplaceview,
        props: true,
      },
    ],
  },
  {
    path: "/employee/management",
    name: "EmployeeManagement",
    component: EmployeeManagement,
    children: [
      {
        path: "",
        name: "EmployeeDefault",
        component: EmployeeList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/employee/list",
        name: "EmployeeList",
        component: EmployeeList,
      },
      {
        path: "/employee/add",
        name: "EmployeeAdd",
        component: EmployeeAdd,
      },
      {
        path: "/employee/view/:id",
        name: "Employeeview",
        component: Employeeview,
        props: true,
      },
      {
        path: "/employee/dept/list",
        name: "DeptList",
        component: DeptList,
      },
      {
        path: "/employee/requestjoin/list",
        name: "RequestJoinList",
        component: RequestJoinList,
      },
      {
        path: "/employee/withdraw/list",
        name: "WithDrawalList",
        component: WithDrawalList,
      },
    ],
  },
  {
    path: "/product/management",
    name: "ProductManagement",
    component: ProductManagement,
    children: [
      {
        path: "",
        name: "ProductDefault",
        component: ProductCategoryList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/product/freeseat/list",
        name: "ProductFreeseatList",
        component: ProductFreeseatList,
      },
      {
        path: "/product/freeseat/add",
        name: "ProductFreeseatAdd",
        component: ProductFreeseatAdd,
      },
      {
        path: "/product/freeseat/view/:id",
        name: "ProductFreeseatView",
        component: ProductFreeseatView,
        props: true,
      },
      {
        path: "/product/meetingroom/list",
        name: "ProductMeetingroomList",
        component: ProductMeetingroomList,
      },
      {
        path: "/product/meetingroom/add",
        name: "ProductMeetingroomAdd",
        component: ProductMeetingroomAdd,
      },
      {
        path: "/product/meetingroom/view/:id",
        name: "ProductMeetingroomView",
        component: ProductMeetingroomView,
        props: true,
      },
      {
        path: "/product/publicroom/list",
        name: "ProductPublicroomList",
        component: ProductPublicroomList,
      },
      {
        path: "/product/publicroom/add",
        name: "ProductPublicroomAdd",
        component: ProductPublicroomAdd,
      },
      {
        path: "/product/publicroom/view/:id",
        name: "ProductPublicroomView",
        component: ProductPublicroomView,
        props: true,
      },
      {
        path: "/product/category/list",
        name: "ProductCategoryList",
        component: ProductCategoryList,
      },
      {
        path: "/product/category/add",
        name: "ProductCategoryAdd",
        component: ProductCategoryAdd,
      },
      {
        path: "/product/category/view/:id",
        name: "ProductCategoryView",
        component: ProductCategoryView,
        props: true,
      },
    ],
  },
  {
    path: "/reservation/management",
    name: "ResrvationManagement",
    component: ResrvationManagement,
    children: [
      {
        path: "",
        name: "ResrvationDefault",
        component: ResrvationMeetingroomStatusList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/reservation/meetingroom/status/list",
        name: "ResrvationMeetingroomStatusList",
        component: ResrvationMeetingroomStatusList,
      },
      {
        path: "/reservation/meetingroom/status/view/:id",
        name: "ResrvationMeetingroomStatusview",
        component: ResrvationMeetingroomStatusview,
        props: true,
      },
      {
        path: "/reservation/freeseat/status/list",
        name: "ResrvationFreeseatList",
        component: ResrvationFreeseatList,
      },
      {
        path: "/reservation/freeseat/status/view/:id",
        name: "ResrvationFreeseatView",
        component: ResrvationFreeseatView,
        props: true,
      },
      {
        path: "/reservation/publicroom/status/list",
        name: "ResrvationPublicroomStatusList",
        component: ResrvationPublicroomStatusList,
      },
      {
        path: "/reservation/publicroom/status/view/:id",
        name: "ResrvationPublicroomStatusview",
        component: ResrvationPublicroomStatusview,
        props: true,
      },
    ],
  },
  {
    path: "/certification/management",
    name: "CertificationManagement",
    component: CertificationManagement,
    children: [
      {
        path: "",
        name: "CertificationDefault",
        component: CertificationList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/certification/list",
        name: "CertificationList",
        component: CertificationList,
      },
      {
        path: "/certification/view",
        name: "Certificationview",
        component: Certificationview,
      },
    ],
  },
  {
    path: "/setting/management",
    name: "SettingManagement",
    component: SettingManagement,
    children: [
      {
        path: "",
        name: "SettingDefault",
        component: SettingNoticeList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/setting/meetingroom/access/control",
        name: "SettingMeetingroomAccessControl",
        component: SettingMeetingroomAccessControl,
      },
      {
        path: "/setting/notice/list",
        name: "SettingNoticeList",
        component: SettingNoticeList,
      },
      {
        path: "/setting/notice/add",
        name: "SettingNoticeAdd",
        component: SettingNoticeAdd,
      },
      {
        path: "/setting/notice/view/:id",
        name: "SettingNoticeView",
        component: SettingNoticeView,
        props: true,
      },
      {
        path: "/setting/privacy/list",
        name: "SettingPrivacyList",
        component: SettingPrivacyList,
        meta: { requiresAuth: true, userType: ["S"] },
      },
      {
        path: "/setting/privacy/add",
        name: "SettingPrivacyAdd",
        component: SettingPrivacyAdd,
        meta: { requiresAuth: true, userType: ["S"] },
      },
      {
        path: "/setting/privacy/view/:id",
        name: "SettingPrivacyView",
        component: SettingPrivacyView,
        meta: { requiresAuth: true, userType: ["S"] },
        props: true,
      },
      {
        path: "/setting/termsofuse/list",
        name: "SettingTermsofuseList",
        component: SettingTermsofuseList,
        meta: { requiresAuth: true, userType: ["S"] },
      },
      {
        path: "/setting/termsofuse/add",
        name: "SettingTermsofuseAdd",
        component: SettingTermsofuseAdd,
        meta: { requiresAuth: true, userType: ["S"] },
      },
      {
        path: "/setting/termsofuse/view/:id",
        name: "SettingTermsofuseView",
        component: SettingTermsofuseView,
        meta: { requiresAuth: true, userType: ["S"] },
        props: true,
      },
      {
        path: "/setting/user/join/list",
        name: "SettingUserJoinColumnList",
        component: SettingUserJoinColumnList,
      },
      {
        path: "/setting/user/join/listTwo",
        name: "SettingUserJoinColumnListTwo",
        component: SettingUserJoinColumnListTwo,
      },
      {
        path: "/setting/etc/noshow/control",
        name: "SettingNoShowControl",
        component: SettingNoShowControl,
      },
      {
        path: "/setting/etc/tablet/list",
        name: "SettingTabletList",
        component: SettingTabletList,
      },
      {
        path: "/setting/etc/tablet/view/:id",
        name: "SettingTabletView",
        component: SettingTabletView,
        props: true,
      },
      {
        path: "/setting/freeseat/type/list",
        name: "SettingFreeSeatTypeList",
        component: SettingFreeSeatTypeList,
      },
      {
        path: "/setting/freeseat/type/alarm_setting",
        name: "SettingFreeSeatTypeAlarmSetting",
        component: SettingFreeSeatTypeAlarmSetting,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 전역 네비게이션 가드
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const userType = store.getters.getUsertype;
    if (!userType) {
      next({ path: "/" });
    } else if (to.meta.userType && !to.meta.userType.includes(userType)) {
      next({ path: "/not-authorized" }); // 접근 권한 없을 때 리다이렉트할 경로
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
