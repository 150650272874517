<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">가입요청 목록</div>
        <div class="description"></div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>가입일시 기간별</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="pop_text"
                        placeholder="시작 날짜를 선택하세요"
                        v-model="startDate"
                      />
                      ~
                      <input
                        type="date"
                        class="pop_text"
                        placeholder="종료 날짜를 선택하세요"
                        v-model="endDate"
                      />
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'today' }"
                        @click="setToday"
                      >
                        당일
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'week' }"
                        @click="setLastWeek"
                      >
                        일주일
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'month' }"
                        @click="setLastMonth"
                      >
                        1개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === '3months' }"
                        @click="setLast3Months"
                      >
                        3개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === '6months' }"
                        @click="setLast6Months"
                      >
                        6개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'A' }"
                        @click="setAllTime"
                      >
                        전체
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>승인상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchApprovalStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchApprovalStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        승인
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchApprovalStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        미승인
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchApprovalStatus === 'C' }"
                        @click="updateStatus('C')"
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchApprovalStatus === 'R' }"
                        @click="updateStatus('R')"
                      >
                        반려
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="userName">이름</option>
                        <option value="userMobile">휴대폰번호</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalRequestJoins }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
            <button type="button" @click.prevent="batchSelected">
              일괄승인
            </button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="requestjoinList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 9%" />
                  <col style="width: 13%" />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 9%" />
                  <col style="width: 17%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>                    
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('userName')" class="sortable">
                      <a>이름</a>
                      <img v-if="OrderType === 'userName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'userName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>휴대폰번호</th>
                    <th @click="sort('requestJoinDtm')" class="sortable">
                      <a>가입일시</a>
                      <img v-if="OrderType === 'requestJoinDtm_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'requestJoinDtm_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('approvalDtm')" class="sortable">
                      <a>상태변경일시</a>
                      <img v-if="OrderType === 'approvalDtm_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'approvalDtm_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>
                      <a href="###">상태</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="requestjoin in paginatedRequestJoins"
                    :key="requestjoin.requestJoinId"
                    class="no_move"
                  >
                    <td class="o1 o_br">
                      <input
                        type="checkbox"
                        @change="toggleSelection(requestjoin.requestJoinId)"
                        :checked="
                          selectedRequestJoins.includes(
                            requestjoin.requestJoinId
                          )
                        "
                      />
                    </td>
                    <td class="num no_mobile">{{ requestjoin.no }}</td>
                    <td class="o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ requestjoin.companyName }}</span>
                    </td>
                    <td class="detail_page_a o4r">
                      <span class="only_mobile">사업장 : </span>
                      <p class="text_bold">{{ requestjoin.workplaceName }}</p>
                    </td>
                    <td class="o2 o_title">
                      <div class="center_td">
                        <a class="center_td">
                          <p class="text_bold">{{ requestjoin.userName }}</p>
                        </a>
                      </div>
                    </td>
                    <td class="num o5 o_br">
                      <span class="only_mobile">휴대폰 : </span>
                      <span>{{ requestjoin.userMobile }}</span>
                    </td>
                    <td class="num o6">
                      <span class="only_mobile">가입 : </span>
                      <span>{{ requestjoin.requestJoinDtm }}</span>
                    </td>
                    <td class="num o7r">
                      <span class="only_mobile">승인 : </span>
                      <span>{{ requestjoin.approvalDtm }}</span>
                    </td>
                    <td class="num o_stat">
                      <div
                        class="stat"
                        :class="{
                          back_red: requestjoin.approvalStatus === 'R',
                          back_green: requestjoin.approvalStatus === 'Y',
                          back_lightgrey: requestjoin.approvalStatus === 'N',
                          back_orange: requestjoin.approvalStatus === 'C',
                        }"
                      >
                        <span v-if="requestjoin.approvalStatus === 'R'"
                          >반려</span
                        >
                        <span v-else-if="requestjoin.approvalStatus === 'Y'"
                          >승인</span
                        >
                        <span v-else-if="requestjoin.approvalStatus === 'N'"
                          >미승인</span
                        >
                        <span v-else-if="requestjoin.approvalStatus === 'C'"
                          >취소</span
                        >
                      </div>
                    </td>
                    <td>
                      <div
                        class="btns"
                        v-if="requestjoin.approvalStatus === 'N'"
                      >
                        <button
                          @click="approveRequest(requestjoin.requestJoinId)"
                        >
                          승인
                        </button>
                        <button
                          @click="rejectRequest(requestjoin.requestJoinId)"
                        >
                          반려
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="10">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 9%" />
                  <col style="width: 13%" />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 9%" />
                  <col style="width: 17%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th><input type="checkbox" /></th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('userName')" class="sortable">
                      <a>이름</a>
                      <img v-if="OrderType === 'userName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'userName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>휴대폰번호</th>
                    <th @click="sort('requestJoinDtm')" class="sortable">
                      <a>가입일시</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('approvalDtm')" class="sortable">
                      <a>상태변경일시</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>
                      <a href="###">상태</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="10">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>

          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 반려하기 modal -->
  <div id="Modal" class="d_pop" style="display: none">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">반려하기</div>
        <a href="###">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>가입요청자</th>
                <td>
                  <div class="inner_td">홍길동</div>
                </td>
              </tr>
              <tr>
                <th>사유입력</th>
                <td>
                  <div class="inner_td textarea_full">
                    <textarea></textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on">반려</button>
        <button type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "RequestJoinList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      selectedPeriod: "A", // 기본값을 'all'로 설정
      requestjoinList: [], // 가입요청 목록 데이터를 저장할 배열
      selectedRequestJoins: [], // 선택된 가입요청 목록의 requestjoinid를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "userName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchApprovalStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalRequestJoins: 0, // 총 가입요청 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
    };
  },
  watch: {
    selectedRequestJoins(newVal) {
      this.allSelected = newVal.length === this.requestjoinList.length;
    },
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedRequestJoins() {
      if (!Array.isArray(this.requestjoinList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.requestjoinList);
      console.log(
        "Paginated requestjoinList: ",
        this.requestjoinList.slice(start, end)
      );
      return this.requestjoinList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalRequestJoins / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    approveRequest(requestJoinId) {
      if (confirm("해당 가입 요청을 승인하시겠습니까?")) {
        this.updateRequestStatus(requestJoinId, "Y");
      }
    },
    rejectRequest(requestJoinId) {
      if (confirm("해당 가입 요청을 반려하시겠습니까?")) {
        this.updateRequestStatus(requestJoinId, "R");
      }
    },
    async updateRequestStatus(requestJoinId, status) {
      try {
        const response = await apiClient.post(
          "/api/RequestJoin/request-join-status-update",
          {
            RequestJoinId: requestJoinId,
            LoginId: localStorage.getItem("userid"), // 현재 로그인한 사용자 ID
            ApprovalStatus: status, // "Y"는 승인, "R"은 반려
          }
        );

        if (response.status === 200) {          
		    this.toast.success("상태가 성공적으로 업데이트되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
		
          this.fetchData(); // 데이터를 다시 가져와서 화면을 갱신합니다.
        } else {
          console.error("상태 업데이트 실패:", response.data);
        }
      } catch (error) {
        console.error("Error updating request status:", error);
      }
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchApprovalStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
          StartDate: this.startDate,
          EndDate: this.endDate,
        };

        const response = await apiClient.post(
          "/api/RequestJoin/all-request-join-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.requestjoinList = Array.isArray(data.data) ? data.data : [];
          this.totalRequestJoins = data.meta.totalCount || 0;

          // 기존 데이터와 새로운 데이터를 비교하여 계속 새로운 데이터를 불러와야 할때 아래처럼 하면 됨
          // if (JSON.stringify(this.noticeList) !== JSON.stringify(data.data)) {
          //   this.noticeList = []; // 기존 데이터 비움
          //   this.$nextTick(() => {
          //     this.noticeList = Array.isArray(data.data) ? data.data : [];
          //     this.totalNotices = data.meta.totalCount || 0;
          //   });
          // } else {
          //   this.noticeList = Array.isArray(data.data) ? data.data : [];
          //   this.totalNotices = data.meta.totalCount || 0;
          // }
        }
      } catch (error) {
        console.error("Error fetching requestjoinList data:", error);
      }
    },
    updateStatus(status) {
      this.SearchApprovalStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.requestjoinList = []; // 회사 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedRequestJoins = this.requestjoinList.map(
          (requestjoin) => requestjoin.requestJoinId
        );
      } else {
        this.selectedRequestJoins = [];
      }
    },
    toggleSelection(requestJoinId) {
      const index = this.selectedRequestJoins.indexOf(requestJoinId);

      if (index > -1) {
        this.selectedRequestJoins.splice(index, 1);
      } else {
        this.selectedRequestJoins.push(requestJoinId); // 기존 코드를 이 줄로 수정
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.requestjoinList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.requestjoinList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.requestjoinList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.requestjoinList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.requestjoinList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.requestjoinList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.requestjoinList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    setToday() {
      const today = new Date().toISOString().slice(0, 10);
      this.startDate = today;
      this.endDate = today;
      this.selectedPeriod = "today"; // 'today'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLastWeek() {
      const today = new Date();
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);

      this.startDate = lastWeek.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "week"; // 'week'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLastMonth() {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);

      this.startDate = lastMonth.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "month"; // 'month'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLast3Months() {
      const today = new Date();
      const last3Months = new Date(today);
      last3Months.setMonth(today.getMonth() - 3);

      this.startDate = last3Months.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "3months"; // '3months'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLast6Months() {
      const today = new Date();
      const last6Months = new Date(today);
      last6Months.setMonth(today.getMonth() - 6);

      this.startDate = last6Months.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "6months"; // '6months'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setAllTime() {
      this.startDate = "";
      this.endDate = "";
      this.selectedPeriod = "A"; // 'all'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    batchSelected() {
      if (this.selectedRequestJoins.length === 0) {
		    this.toast.error("원하시는 일괄승인 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return;
      }

      // 미승인이 아닌 항목이 선택된 경우 필터링
      const invalidSelections = this.requestjoinList.filter(
        (requestjoin) =>
          this.selectedRequestJoins.includes(requestjoin.requestJoinId) &&
          requestjoin.approvalStatus !== "N" // 미승인이 아닌 항목 필터링
      );

      if (invalidSelections.length > 0) {
		    this.toast.error("이미 승인 또는 반려된 건이 존재합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return; // 일괄승인 작업 중단
      }

      if (confirm("선택된 항목을 일괄 승인하시겠습니까?")) {
        this.BatchApproval(this.selectedRequestJoins);
      }
    },
    async BatchApproval(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/RequestJoin/request-join-status-update", {
            RequestJoinId: id,
            LoginId: localStorage.getItem("userid"),
            ApprovalStatus: "Y",
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to request-join-status-update for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedRequestJoins = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error request-join-status-update:", error);
      }
    },
    activateSelected() {
      if (this.selectedCompanies.length === 0) {
		    this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return;
      }

      if (confirm("선택된 항목을 활성화하시겠습니까?")) {
        this.updateCompanyStatus(this.selectedCompanies, "Y");
      }
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  mounted() {
    if (this.userType === "C") {
      const companyId = localStorage.getItem("companyid");
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = localStorage.getItem("companyid");
      const workplaceId = localStorage.getItem("workplaceid");

      // 회사 및 사업장 정보를 설정
      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      // 데이터를 바로 가져옴
      this.fetchData();
    } else {
      this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
