<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">개인정보처리방침 목록</div>
        <div class="description">
          개인정보처리방침 조회하고 관리할 수 있습니다.
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'A' }"
                        @click="
                          updateStatus('A');
                          fetchData();
                        "
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'Y' }"
                        @click="
                          updateStatus('Y');
                          fetchData();
                        "
                      >
                        표시
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchActiveStatus === 'N' }"
                        @click="
                          updateStatus('N');
                          fetchData();
                        "
                      >
                        미표시
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ totalPrivacys }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
            <button type="button" @click="goToPrivacyAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="privacyList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 7%" />
                  <col />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a>번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('selDate')" class="sortable">
                      <a>시행일</a>
                      <img v-if="OrderType === 'selDate_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'selDate_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="privacy in paginatedPrivacys"
                    :key="privacy.privacyId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        :checked="selectedPrivacys.includes(privacy.privacyId)"
                        @change="toggleSelection(privacy.privacyId)"
                      />
                    </td>
                    <td class="no_mobile">{{ privacy.no }}</td>
                    <td class="detail_page_a long_txt o2 o_title">
                      <div>
                        <router-link
                          :to="{
                            name: 'SettingPrivacyView',
                            params: { id: privacy.privacyId || 0 },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold" style="max-width: 280px">
                            <span v-if="privacy.currentYN === 'Y'">
                              [현행]
                            </span>
                            {{ formatDate(privacy.selDate) }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="center_td o_stat">
                      <div
                        class="stat"
                        :class="{
                          back_green: privacy.activeStatus === 'Y',
                          back_red: privacy.activeStatus === 'N',
                        }"
                      >
                        <span>{{
                          privacy.activeStatus === "Y" ? "표시" : "미표시"
                        }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 7%" />
                  <col />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('selDate')" class="sortable">
                      <a>시행일</a>
                      <img v-if="OrderType === 'selDate_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'selDate_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="3">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="activateSelected">표시</a>
              <a @click.prevent="deactivateSelected">미표시</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "SettingPrivacyList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      privacyList: [], // 이용약관 목록 데이터를 저장할 배열
      selectedPrivacys: [], // 선택된 이용약관들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchActiveStatus: "A", // 진열 상태별값
      OrderType: "createDtm_desc", //
      totalPrivacys: 0, // 총 이용약관 수
    };
  },
  watch: {
    selectedPrivacys(newVal) {
      this.allSelected = newVal.length === this.privacyList.length;
    },
  },
  computed: {
    // 현재 페이지에 해당하는 이용약관 목록을 반환
    paginatedPrivacys() {
      if (!Array.isArray(this.privacyList)) {
        return [];
      }

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      console.log(this.privacyList);
      return this.privacyList.slice(start, end).map((privacy) => {
        // 생성일과 수정일은 그냥 풀로 넘기고 있음 (yyyy-mm-dd 만 사용하기 위하여 아래 소스 추가)
        return {
          ...privacy,
        };
      });
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalPrivacys / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > this.pageGroupSize;
    },
    hasNextPageGroup() {
      return this.currentPage + this.pageGroupSize <= this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${year}년 ${month}월 ${day}일 시행안`;
    },
    async fetchData() {
      try {
        const searchParam = {
          SearchActiveStatus: this.SearchActiveStatus, // 진열상태별 값 추가
          OrderType: this.OrderType,
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
        };
        console.log(searchParam);
        const response = await apiClient.post(
          "/api/PrivacyPolicy/all-privacy-list",
          searchParam
        );
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.privacyList = Array.isArray(data.data) ? data.data : [];
          this.totalPrivacys = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching privacy data:", error);
      }
    },
    updateStatus(status) {
      this.SearchActiveStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.privacyList = []; // 이용약관 목록 초기화
      this.fetchData();
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.privacyList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.privacyList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.privacyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.privacyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.privacyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.privacyList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    goToPrivacyAdd() {
      this.$router.push({ path: "/setting/privacy/add" });
    },
    async updatePrivacyStatus(selectedIds, status) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/PrivacyPolicy/privacy-active-update", {
            PrivacyId: id,
            ActiveStatus: status,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status === 200) {
            const id = selectedIds[index];
            // 선택된 이용약관들의 상태 업데이트
            this.privacyList.forEach((privacy) => {
              if (privacy.PrivacyId === id) {
                privacy.activeStatus = status;
              }
            });
          } else {
            console.error(
              `Failed to update privacy status for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedPrivacys = [];
        this.allSelected = false; // thead 체크박스 해제
        this.fetchData();
      } catch (error) {
        console.error("Error updating privacy status:", error);
      }
    },
    async DeletePrivacy(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/PrivacyPolicy/privacy-delete", {
            PrivacyId: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete privacy for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedPrivacys = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting companies:", error);
      }
    },
    activateSelected() {
      if (this.selectedPrivacys.length === 0) {
		    this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      if (confirm("선택된 항목을 표시 처리하시겠습니까?")) {
        this.updatePrivacyStatus(this.selectedPrivacys, "Y");
      }
    },
    deactivateSelected() {
      if (this.selectedPrivacys.length === 0) {
		    this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 미표시 처리하시겠습니까?")) {
        this.updatePrivacyStatus(this.selectedPrivacys, "N");
      }
    },
    deleteSelected() {
      if (this.selectedPrivacys.length === 0) {
		    this.toast.error("원하시는 삭제 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 삭제하시겠습니까?")) {
        this.DeletePrivacy(this.selectedPrivacys);
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedPrivacys = this.privacyList.map(
          (privacy) => privacy.privacyId
        );
      } else {
        this.selectedPrivacys = [];
      }
    },
    toggleSelection(privacyId) {
      const index = this.selectedPrivacys.indexOf(privacyId);
      if (index > -1) {
        this.selectedPrivacys.splice(index, 1);
      } else {
        this.selectedPrivacys.push(privacyId); // 기존 코드를 이 줄로 수정
      }
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
