<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">상품분류 등록</div>
        <div class="description">
          상품분류를 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>회사명<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <!-- 회사 선택 후 class="disabled_input"추가 -->
                    <input
                      type="text"
                      class="w_250 disabled_input"
                      :value="selectedCompanyName"
                      readonly
                    />
                    <!-- <button
                      v-if="userType !== 'W'"
                      @click="handleCompanySelection"
                    > -->
                    <button @click="handleCompanySelection">선택</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>사업장<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250 disabled_input"
                    :value="selectedWorkplaceName"
                    readonly
                  />
                </td>
              </tr>
              <!-- 회사, 사업장 선택 시 나타남 -->
              <tr>
                <th>상품유형<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('')"
                      :class="{ on: productTypeSelect === '' }"
                      @click="setProductTypeSelect('')"
                    >
                      미정
                    </button>
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('M')"
                      :class="{ on: productTypeSelect === 'M' }"
                      @click="setProductTypeSelect('M')"
                    >
                      회의실
                    </button>
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('S')"
                      :class="{ on: productTypeSelect === 'S' }"
                      @click="setProductTypeSelect('S')"
                    >
                      자율좌석
                    </button>
                    <button
                      type="button"
                      v-if="selectedWorkplaceProductType.includes('P')"
                      :class="{ on: productTypeSelect === 'P' }"
                      @click="setProductTypeSelect('P')"
                    >
                      공용시설
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>분류이름<span class="text_red">*</span></th>
                <td>
                  <input
                    type="text"
                    class="w_250"
                    placeholder="분류이름을 입력해주세요"
                    v-model="productCategoryName"
                    ref="productCategoryName"
                  />
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <div class="inner_td">
                    <textarea
                      placeholder="상세 설명을 입력해주세요"
                      v-model="detailDesc"
                      ref="detailDesc"
                    ></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상위분류</th>
                <td>
                  <div class="inner_td open_list">
                    <!-- '선택하기' 클릭 시 id="Modal" 나타남 -->
                    <button type="button" @click="showModal2">선택하기</button>
                    <ul
                      class="attendee_add group_choice"
                      v-if="selectedParentName"
                    >
                      <li>
                        <div>
                          <span>{{ selectedParentName }}</span>
                        </div>
                        <a href="###" @click="clearParentCategory">⨉</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">진열정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: activeStatusSelect === 'Y' }"
                      @click="setActiveStatusSelect('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: activeStatusSelect === 'N' }"
                      @click="setActiveStatusSelect('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>정렬옵션</th>
                <td>
                  <div class="inner_td">
                    <select v-model="sortOption" class="w_250">
                      <option value="recent_desc">최근 등록상품이 위로</option>
                      <option value="recent_asc">최근 등록상품이 아래로</option>
                      <option value="modified_desc">
                        최근 수정상품이 위로
                      </option>
                      <option value="modified_asc">
                        최근 수정상품이 아래로
                      </option>
                      <option value="name_asc">상품명 가나다순</option>
                      <option value="name_desc">상품명 가나다역순</option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a href="###" class="on" @click="saveProductCategory">등록</a>
            <a href="###" @click="goToProductCategoryList">취소</a>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 회사/사업장 선택 modal -->
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">회사/사업장 선택</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="SearchType">
                      <option value="companyName">회사명</option>
                      <option value="workplaceName">사업장명</option>
                    </select>
                    <input
                      type="text"
                      class="w_200"
                      v-model="SearchKey"
                      placeholder="검색어를 입력해주세요"
                      @keyup.enter="searchCompanies"
                    />
                    <button type="button" @click="searchCompanies">검색</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>회사명</th>
                <th>사업장명</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="companyworkplace in filteredCompanyWorkplaces"
                :key="companyworkplace.workplaceId"
              >
                <td>{{ companyworkplace.companyName }}</td>
                <!-- paginatedCompanyWorkplaces -->
                <td>
                  {{ companyworkplace.workplaceName }}
                </td>
                <!-- workplaceProductType -->
                <td>
                  <button
                    class="keep"
                    @click="selectCompanyWorkplace(companyworkplace)"
                  >
                    선택
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup"
              @click.prevent="prevPageGroup"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
              ><img src="@/images/prev_n.png" alt="이전페이지"
            /></a>
            <a
              v-for="page in pageGroup"
              :key="page"
              href="###"
              :class="{ on: currentPage === page }"
              @click.prevent="goToPage(page)"
            >
              {{ page }}
            </a>
            <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
              ><img src="@/images/next_n.png" alt="다음페이지"
            /></a>
            <a href="###" v-if="hasNextPageGroup" @click.prevent="nextPageGroup"
              ><img src="@/images/end_n.png" alt="마지막페이지"
            /></a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //회사/사업장 선택 modal -->
  <!-- 상위분류 선택 modal -->
  <div id="Modal2" class="d_pop" v-if="isModal2Visible">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">상위분류 선택</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>표시상태</th>
                <td>
                  <div class="inner_td">
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'A' }"
                      @click="setModal2Status('A')"
                    >
                      전체
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'Y' }"
                      @click="setModal2Status('Y')"
                    >
                      표시
                    </button>
                    <button
                      type="button"
                      :class="{ on: modal2Status === 'N' }"
                      @click="setModal2Status('N')"
                    >
                      미표시
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>키워드 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                      tyoe="text"
                      class="w_200"
                      v-model="SearchKey2"
                      placeholder="분류명을 입력해주세요"
                      @keyup.enter="searchProductcategorys"
                    />
                    <button type="button" @click="searchProductcategorys">
                      검색
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tbl_list">
          <table>
            <colgroup>
              <col style="width: 30%" />
              <col />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>분류명</th>
                <th>표시상태</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="productCategory in filteredProductCategorys"
                :key="productCategory.id"
              >
                <td>{{ productCategory.productName }}</td>
                <td>
                  {{ productCategory.activeStatus === "Y" ? "표시" : "미표시" }}
                </td>
                <td>
                  <button
                    class="keep"
                    @click="selectParentCategory(productCategory)"
                  >
                    선택
                  </button>
                </td>
              </tr>
              <!-- <tr>
                <td>27층</td>
                <td>표시</td>
                <td><button class="keep">선택</button></td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!--Paging-->
        <div class="page_num">
          <div class="pageing">
            <a
              href="###"
              v-if="hasPrevPageGroup2"
              @click.prevent="prevPageGroup2"
            >
              <img src="@/images/front_n.png" alt="처음페이지" />
            </a>
            <a href="###" v-if="hasPrevPage2" @click.prevent="prevPage2">
              <img src="@/images/prev_n.png" alt="이전페이지" />
            </a>
            <a
              v-for="page2 in pageGroup2"
              :key="page2"
              href="###"
              :class="{ on: currentPage2 === page2 }"
              @click.prevent="goToPage2(page2)"
            >
              {{ page2 }}
            </a>
            <a href="###" v-if="hasNextPage2" @click.prevent="nextPage2">
              <img src="@/images/next_n.png" alt="다음페이지" />
            </a>
            <a
              href="###"
              v-if="hasNextPageGroup2"
              @click.prevent="nextPageGroup2"
            >
              <img src="@/images/end_n.png" alt="마지막페이지" />
            </a>
          </div>
        </div>
        <!--//Paging-->
      </div>
    </div>
  </div>
  <!-- //상위분류 선택 modal -->
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "ProductCategoryAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedParentId: null, // 선택된 상위분류 ID
      selectedParentName: "", // 선택된 상위분류 이름
      sortOption: "recent_desc", // 정렬 옵션의 기본값 설정
      isModalVisible: false, // 회사 선택 모달
      isModal2Visible: false, // 상위분류 선택 모달 상태
      modal2Status: "A", // 상위분류 모달의 상태 기본값을 '전체'로 설정
      companyworkplaces: [], // API 에서 가져온 회사-사업장 목록 데이터를 저장할 배열
      productcategorys: [], // API 에서 가져온 상품분류 목록 데이터를 저장할 배열
      productTypeSelect: "", // 상품유형 '회의실-Meeting','자율좌석-Seat'
      activeStatusSelect: "Y", // 표시상태 '표시-Y', '미표시-N'
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      totalCompanyWorkplaces: 0, // 총 회사-사업자 수
      totalProductCategorys: 0, // 총 상위분류 수

      selectedCompanyName: "", // 선택된 회사명
      selectedWorkplaceName: "", // 선택된 사업장명
      selectedCompanyId: 0, // 선택된 회사 ID
      selectedWorkplaceId: 0, // 선택된 사업장 ID
      selectedWorkplaceProductType: "", // 선택된 사업장의 상품유형

      SearchType: "companyName", // 기본값은 'companyName'으로 설정
      SearchKey: "", // 검색어를 입력받기 위한 변수
      filteredCompanyWorkplaces: [], // 검색된 결과를 저장할 배열

      SearchType2: "productName",
      SearchKey2: "", // 검색어를 입력받기 위한 변수
      filteredProductCategorys: [], // 검색된 결과를 저장할 배열
      pageGroupSize2: 3, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      currentPage2: 1, // 현재 페이지
      itemsPerPage2: 5, // 페이지 당 항목 수
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      console.log("Company ID:", this.$store.getters.getCompanyid);
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      console.log("Workplace ID:", this.$store.getters.getWorkplaceid);
      return this.$store.getters.getWorkplaceid;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedCompanyWorkplaces() {
      if (!Array.isArray(this.companyworkplaces)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(
        "Paginated companyworkplaces: ",
        this.companyworkplaces.slice(start, end)
      );
      return this.companyworkplaces.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalCompanyWorkplaces / this.itemsPerPage);
    },
    // 총 페이지 수 계산
    totalPages2() {
      return Math.ceil(this.totalProductCategorys / this.itemsPerPage2);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start = Math.floor((this.currentPage - 1) / this.pageGroupSize) * this.pageGroupSize + 1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    // 현재 페이지 그룹 계산
    pageGroup2() {
      const start = Math.floor((this.currentPage2 - 1) / this.pageGroupSize2) * this.pageGroupSize2 + 1;
      const end = Math.min(start + this.pageGroupSize2 - 1, this.totalPages2);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup2() {
      return this.currentPage2 > 1;
    },
    hasNextPageGroup2() {
      return this.currentPage2 < this.totalPages2;
    },
    hasPrevPage2() {
      return this.currentPage2 > 1;
    },
    hasNextPage2() {
      return this.currentPage2 < this.totalPages2;
    },
  },
  mounted() {
    this.loadWorkplaces();
    this.loadProductcategorys();
  },
  methods: {
    searchCompanies() {
      this.loadWorkplaces();
    },
    searchProductcategorys() {
      this.loadProductcategorys();
    },
    clearParentCategory() {
      this.selectedParentId = null;
      this.selectedParentName = "";
    },
    selectParentCategory(category) {
      this.selectedParentId = category.id;
      this.selectedParentName = category.productName;
      this.hideModal2(); // 모달 닫기
    },
    setModal2Status(status) {
      this.modal2Status = status;
      this.loadProductcategorys();
    },
    goToPage(page) {
      this.currentPage = page;
      this.companyworkplaces = []; // 페이지 변경 시 목록 초기화
      this.loadWorkplaces();
    },
    goToPage2(page) {
      this.currentPage2 = page;
      this.productcategorys = []; // 페이지 변경 시 목록 초기화
      this.loadProductcategorys();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      if (this.hasNextPageGroup) {
        this.currentPage = this.totalPages;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup() {
      if (this.hasPrevPageGroup) {
        this.currentPage = 1;
        this.loadWorkplaces(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPage2() {
      if (this.currentPage2 < this.totalPages2) {
        this.currentPage2++;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage2() {
      if (this.currentPage2 > 1) {
        this.currentPage2--;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup2() {
      if (this.hasNextPageGroup2) {
        this.currentPage2 = this.totalPages2;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPageGroup2() {
      if (this.hasPrevPageGroup2) {
        this.currentPage2 = 1;
        this.loadProductcategorys(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    selectCompanyWorkplace(companyworkplace) {
      this.selectedCompanyName = companyworkplace.companyName;
      this.selectedWorkplaceName = companyworkplace.workplaceName;
      this.selectedCompanyId = companyworkplace.companyId;
      this.selectedWorkplaceId = companyworkplace.workplaceId;
      this.selectedWorkplaceProductType = companyworkplace.workplaceProductType; // 선택된 사업장의 상품유형 저장
      this.hideModal();
    },
    goToProductCategoryList() {
      this.$router.push({ path: "/product/category/list" });
    },
    handleCompanySelection() {
      this.showModal();
    },
    showModal() {
      this.isModalVisible = true;
      this.SearchKey = ""; // 검색어 초기화
      this.filteredCompanyWorkplaces = [...this.companyworkplaces]; // 검색 결과 초기화
    },
    hideModal() {
      this.isModalVisible = false;
    },
    showModal2() {
      this.isModal2Visible = true;
      this.SearchKey2 = ""; // 검색어 초기화
      this.filteredProductCategorys = [...this.productcategorys]; // 검색 결과 초기화
    },
    hideModal2() {
      this.isModal2Visible = false;
    },
    async saveProductCategory() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post("/api/Product/product-add", {
            CompanyId: this.selectedCompanyId,
            WorkplaceId: this.selectedWorkplaceId,
            ProductType: this.productTypeSelect, // 상품유형 '회의실-Meeting','자율좌석-Seat'
            ProductName: this.productCategoryName,
            ProductDesc: this.detailDesc,
            ParentId: this.selectedParentId, // 선택된 상위분류 ID 추가
            ActiveStatus: this.activeStatusSelect, // 표시상태 '표시-Y', '미표시-N'
            OrderOption: this.sortOption,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          });

          if (response.status === 200) {
            this.toast.success("상품 분류가 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            this.goToProductCategoryList();
          }
        } catch (error) {
          console.error("Error saving product:", error);
          this.toast.error("상품 분류 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
		
        }
      }
    },
    checkFields() {
      if (this.selectedCompanyId === 0 || this.selectedWorkplaceId === 0) {
		    this.toast.error("회사 및 사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.isModalVisible = true;
        return;
      }
      if (this.productTypeSelect === '') {
        this.toast.error("상품유형을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        return;
      }
      if (!this.productCategoryName) {
		    this.toast.error("분류 이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.productCategoryName.focus();
        return false;
      }
      if (!this.detailDesc) {
		    this.toast.error("상세 설명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.detailDesc.focus();
        return false;
      }
      return true;
    },
    async loadProductcategorys() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId = this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
          v_workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage2,
          PageSize: this.itemsPerPage2,
          OrderType: "",
          SearchActiveStatus: this.modal2Status, // 상태별 값 추가
          SearchType: this.SearchType2,
          SearchKey: this.SearchKey2,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Product/all-product-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.productcategorys = Array.isArray(data.data) ? data.data : [];
          this.totalProductCategorys = data.meta.totalCount || 0;
          this.filteredProductCategorys = this.productcategorys;
        }
      } catch (error) {
        console.error("Error loading productcategorys:", error);
      }
    },
    async loadWorkplaces() {
      let v_companyId = "";
      let v_workplaceId = "";
      // 슈퍼관리자면 회사 선택 골라야 하고
      // 회사관리자면 본인 회사 관련된 사업장 리스트만 나와야 하고
      // 사업장관리자면 선택 버튼 자체가 안보여야 함
      if (this.userType === "S") {
        v_companyId = "";
      }

      if (this.userType === "C") {
        v_companyId = this.companyId;
      }

      if (this.userType === "W") {
        v_companyId = this.companyId;
        v_workplaceId = this.workplaceId;
      }

      try {
        const searchParam = {
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          SearchActiveStatus: "Y", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: this.itemsPerPage,
          Page: this.currentPage,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정 //workplaceProductType
          WorkplaceId: v_workplaceId,
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.companyworkplaces = Array.isArray(data.data) ? data.data : [];
          this.totalCompanyWorkplaces = data.meta.totalCount || 0;
          this.filteredCompanyWorkplaces = this.companyworkplaces;
          console.log(this.filteredCompanyWorkplaces[0]);
        }
      } catch (error) {
        console.error("Error loading workplaces:", error);
      }
    },
    setProductTypeSelect(status) {
      this.productTypeSelect = status;
    },
    setActiveStatusSelect(status) {
      this.activeStatusSelect = status;
    },
  },
};
</script>

<style scoped></style>
