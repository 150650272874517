<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_56.png" alt="" />인증관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/certification/list"
            :class="{ on: selectedMenu === 0 }"
            @click="selectMenu(0)"
          >
            인증내역 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/certification/view"
            :class="{ on: selectedMenu === 1 }"
            @click="selectMenu(1)"
          >
            인증내역 상세보기
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Certification_Left",
  data() {
    return {
      selectedMenu: null,
    };
  },
  computed: {
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", "/certification/management"); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (
        path === "/certification/management" ||
        path === "/certification/list"
      ) {
        this.selectedMenu = 0;
      } else if (path === "/certification/view") {
        this.selectedMenu = 1;
      }
      this.$store.dispatch("updateSelectedMenu", "/certification/management"); // Vuex 상태 업데이트
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
