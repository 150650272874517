<template>
  <svg
    v-if="icon === 'close'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    :width="computedWidth"
    :height="computedHeight"
    @click="$emit('click')"
  >
    <path
      fill="#000"
      d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
    />
  </svg>
  <svg
    v-if="icon === 'info'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8-4a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm.01 8a1 1 0 102 0V9a1 1 0 10-2 0v5z"
    />
  </svg>
  <svg
    v-if="icon === 'plus'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="computedWidth"
    :height="computedHeight"
  >
    <path
      d="M9 12H15"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 9L12 15"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="#323232"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "24px", // 기본 크기
    },
    height: {
      type: String,
      default: "24px", // 기본 크기
    },
  },
  computed: {
    computedWidth() {
      return this.width;
    },
    computedHeight() {
      return this.height;
    },
  },
};
</script>

<style scoped>
/* 필요에 따라 스타일을 추가하세요 */
</style>
