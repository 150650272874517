<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">직원탈퇴 목록</div>
        <div class="description">
          탈퇴한 직원을 조회하고 관리할 수 있습니다.
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>탈퇴기간별</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="pop_text"
                        placeholder="시작 날짜를 선택하세요"
                        v-model="startDate"
                      />
                      ~
                      <input
                        type="date"
                        class="pop_text"
                        placeholder="종료 날짜를 선택하세요"
                        v-model="endDate"
                      />
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'today' }"
                        @click="setToday"
                      >
                        당일
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'week' }"
                        @click="setLastWeek"
                      >
                        일주일
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'month' }"
                        @click="setLastMonth"
                      >
                        1개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === '3months' }"
                        @click="setLast3Months"
                      >
                        3개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === '6months' }"
                        @click="setLast6Months"
                      >
                        6개월
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedPeriod === 'A' }"
                        @click="setAllTime"
                      >
                        전체
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>탈퇴유형별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchWithdrawStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchWithdrawStatus === 'S' }"
                        @click="updateStatus('S')"
                      >
                        본인탈퇴
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchWithdrawStatus === 'F' }"
                        @click="updateStatus('F')"
                      >
                        강제탈퇴
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>이름검색</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="이름을 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalWithdraws }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
            <!-- 직원탈퇴 목록에서 일괄승인이 왜 필요하지 -->
            <button type="button" style="display: none">일괄승인</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="withDrawList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 9%" />
                  <col style="width: 13%" />
                  <col style="width: 10%" />
                  <!-- <col style="width: 10%" /> -->
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('userName')" class="sortable">
                      <a>이름</a>
                      <img v-if="OrderType === 'userName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'userName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('withDrawType')" class="sortable">
                      <a>탈퇴유형</a>
                      <img v-if="OrderType === 'withDrawType_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'withDrawType_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('withDrawDtm')" class="sortable">
                      <a>탈퇴일</a>
                      <img v-if="OrderType === 'withDrawDtm_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'withDrawDtm_desc'" src="@/images/down_icon_s.png" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="withdraw in paginatedWithDraws"
                    :key="withdraw.requestJoinId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(withdraw.requestJoinId)"
                        :checked="
                          selectedWithDraws.includes(withdraw.requestJoinId)
                        "
                      />
                    </td>
                    <td class="num no_mobile">{{ withdraw.no }}</td>
                    <td class="o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ withdraw.companyName }}</span>
                    </td>
                    <td class="detail_page_a o4r">
                      <span class="only_mobile">사업장 : </span>
                      <p class="text_bold">{{ withdraw.workplaceName }}</p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <a href="###" class="center_td">
                          <p class="text_bold">{{ withdraw.userName }}</p>
                          <img src="@/images/detail_page.png" />
                        </a>
                      </div>
                    </td>
                    <td class="o5">
                      <div class="center_td">
                        <a href="###" class="center_td">
                          <span class="only_mobile">탈퇴유형 : </span>
                          <p class="text_bold">
                            {{
                              withdraw.withDrawType === "S"
                                ? "본인탈퇴"
                                : withdraw.withDrawType === "F"
                                ? "강제탈퇴"
                                : "알 수 없음"
                            }}
                          </p>
                        </a>
                      </div>
                    </td>
                    <td class="num o6r">
                      <span class="only_mobile">승인 : </span>
                      <span>{{ withdraw.withDrawDtm }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="6">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 9%" />
                  <col style="width: 13%" />
                  <col style="width: 10%" />
                  <!-- <col style="width: 10%" /> -->
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th><input type="checkbox" /></th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>                    
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('userName')" class="sortable">
                      <a>이름</a>
                      <img v-if="OrderType === 'userName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'userName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('withDrawType')" class="sortable">
                      <a>탈퇴유형</a>
                      <img v-if="OrderType === 'withDrawType_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'withDrawType_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('withDrawDtm')" class="sortable">
                      <a>탈퇴일</a>
                      <img v-if="OrderType === 'withDrawDtm_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'withDrawDtm_desc'" src="@/images/down_icon_s.png" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="6">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "WithDrawalList",
  data() {
    return {
      startDate: "",
      endDate: "",
      selectedPeriod: "A", // 기본값을 'all'로 설정
      withDrawList: [], // 직원탈퇴 목록 데이터를 저장할 배열
      selectedWithDraws: [], // 선택된 직원탈퇴 목록의 userid??를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "userName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchWithdrawStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalWithdraws: 0, // 총 직원탈퇴 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
    };
  },
  watch: {
    selectedWithDraws(newVal) {
      this.allSelected = newVal.length === this.withDrawList.length;
    },
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedWithDraws() {
      if (!Array.isArray(this.withDrawList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.withDrawList);
      console.log(
        "Paginated withDrawList: ",
        this.withDrawList.slice(start, end)
      );
      return this.withDrawList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalWithdraws / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchWithdrawStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
          StartDate: this.startDate,
          EndDate: this.endDate,
        };

        const response = await apiClient.post(
          "/api/Employee/all-withdraw-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.withDrawList = Array.isArray(data.data) ? data.data : [];
          this.totalWithdraws = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching withDrawList data:", error);
      }
    },
    updateStatus(status) {
      this.SearchWithdrawStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.withDrawList = []; // 목록 초기화
      this.fetchData();
    },
    // toggleAllSelections() {
    //   if (this.allSelected) {
    //     this.selectedRequestJoins = this.requestjoinList.map(
    //       (requestjoin) => requestjoin.requestJoinId
    //     );
    //   } else {
    //     this.selectedRequestJoins = [];
    //   }
    // },
    // toggleSelection(requestJoinId) {
    //   const index = this.selectedRequestJoins.indexOf(requestJoinId);

    //   if (index > -1) {
    //     this.selectedRequestJoins.splice(index, 1);
    //   } else {
    //     this.selectedRequestJoins.push(requestJoinId); // 기존 코드를 이 줄로 수정
    //   }
    // },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.withDrawList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.withDrawList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.withDrawList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.withDrawList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.withDrawList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.withDrawList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.withDrawList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    setToday() {
      const today = new Date().toISOString().slice(0, 10);
      this.startDate = today;
      this.endDate = today;
      this.selectedPeriod = "today"; // 'today'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLastWeek() {
      const today = new Date();
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);

      this.startDate = lastWeek.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "week"; // 'week'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLastMonth() {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);

      this.startDate = lastMonth.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "month"; // 'month'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLast3Months() {
      const today = new Date();
      const last3Months = new Date(today);
      last3Months.setMonth(today.getMonth() - 3);

      this.startDate = last3Months.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "3months"; // '3months'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setLast6Months() {
      const today = new Date();
      const last6Months = new Date(today);
      last6Months.setMonth(today.getMonth() - 6);

      this.startDate = last6Months.toISOString().slice(0, 10);
      this.endDate = today.toISOString().slice(0, 10);
      this.selectedPeriod = "6months"; // '6months'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    setAllTime() {
      this.startDate = "";
      this.endDate = "";
      this.selectedPeriod = "A"; // 'all'로 업데이트
      this.fetchData(); // fetchData 호출
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  mounted() {
    if (this.userType === "C") {
      const companyId = localStorage.getItem("companyid");
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = localStorage.getItem("companyid");
      const workplaceId = localStorage.getItem("workplaceid");

      // 회사 및 사업장 정보를 설정
      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      // 데이터를 바로 가져옴
      this.fetchData();
    } else {
      this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
