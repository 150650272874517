<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">소속 목록</div>
        <div class="description">
          소속을 관리합니다.<br />해당 항목에 마우스 오버 시, 메뉴가 나옵니다.(추가, 수정, 삭제)
        </div>
        <article class="right_body flex_row">
          <div class="right_body_column">
            <div class="box_tree">
              <!-- 트리 구조의 부서 목록을 렌더링 -->
              <div v-if="departments.length > 0">
                <tree-item 
                  v-for="department in departments" 
                  :key="department.id" 
                  :department="department" 
                  @select="selectDepartment"
                  @add-child="addItem"
                  @edit-department="editItem"
                  @delete-department="deleteItem"
                ></tree-item>
              </div>
            </div>
          </div>
          <div class="right_body_column flex1_5">
            <div class="box_table">
              <div class="table_list_top count_page">
                <div class="table_list_stitle">인원보기</div>
                <div class="count_num">
                  <span>총</span>
                  <span class="accent_color">{{ this.totalUsers }}</span>
                  <span>건</span>
                  <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                    <option selected="selected" value="5">5</option>
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="300">300</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                  <span>개씩</span>
                </div>
              </div>
              <div class="table_list tbl_list table_m mobile_table">
                <table>
                  <colgroup>
                    <col style="width: 11%" />
                    <col style="width: 19%" />
                    <col style="width: 16%" />
                    <col />
                  </colgroup>
                  <thead class="no_mobile">
                    <tr>
                      <th>
                        <a href="###">번호</a>
                        <img
                          src="@/images/up_icon_s.png"
                          style="display: none"
                        />
                        <img
                          src="@/images/down_icon_s.png"
                          style="display: none"
                        />
                      </th>
                      <th @click="sort('userName')" class="sortable">
                        <a>이름</a>
                        <img v-if="OrderType === 'userName_asc'" src="@/images/up_icon_s.png" />
                        <img v-if="OrderType === 'userName_desc'" src="@/images/down_icon_s.png" />
                      </th>
                      <th @click="sort('positionName')" class="sortable">
                        <a>직책</a>
                        <img v-if="OrderType === 'positionName_asc'" src="@/images/up_icon_s.png" />
                        <img v-if="OrderType === 'positionName_desc'" src="@/images/down_icon_s.png" />
                      </th>
                      <th>
                        <a href="###">소속</a>
                        <img
                          src="@/images/up_icon_s.png"
                          style="display: none"
                        />
                        <img
                          src="@/images/down_icon_s.png"
                          style="display: none"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="userList.length > 0">
                    <tr style="justify-content: flex-start" v-for="user in paginatedUsers" :key="user.userId">
                      <td class="no_mobile">{{ user.no }}</td>
                      <td class="seat o2" style="flex-basis: unset">
                        <span class="only_mobile">이름 : </span>
                        <span>{{ user.userName }}</span>
                      </td>
                      <td class="detail_page_a o3">
                        <div class="center_td">
                          <a href="###" class="center_td">
                            <p class="text_bold long_p">{{ user.position }}</p>
                          </a>
                        </div>
                      </td>
                      <td class="seat o1 o_br">
                        <span class="only_mobile">소속 : </span>
                        <p class="long_p_100">{{ user.departPath }}</p>
                      </td>
                    </tr> 
                  </tbody>
                  <tbody v-else>
                    <tr class="no_data">
                      <td colspan="8">데이터가 없습니다</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- 페이징 -->
              <div class="page_num" v-if="userList.length > 0">
                <div class="pageing">
                  <a
                    href="###"
                    v-if="hasPrevPageGroup"
                    @click.prevent="prevPageGroup"
                    ><img src="@/images/front_n.png" alt="처음페이지"
                  /></a>
                  <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                    ><img src="@/images/prev_n.png" alt="이전페이지"
                  /></a>
                  <a
                    v-for="page in pageGroup"
                    :key="page"
                    href="###"
                    :class="{ on: currentPage === page }"
                    @click.prevent="goToPage(page)"
                  >
                    {{ page }}
                  </a>
                  <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                    ><img src="@/images/next_n.png" alt="다음페이지"
                  /></a>
                  <a
                    href="###"
                    v-if="hasNextPageGroup"
                    @click.prevent="nextPageGroup"
                    ><img src="@/images/end_n.png" alt="마지막페이지"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/apiClient';
import TreeItem from './TreeItem.vue';

export default {
  components: {
    TreeItem
  },
  data() {
    return {
      departments: [],      
      userList: [],
      currentPage: 1,
      itemsPerPage: 10,
      pageGroupSize: 5,
      totalUsers: 0,
      selectedDepart: null,      
      OrderType: "",
    };
  },
  async mounted() {
    await this.getMyDepartAllList();
  },
  computed: {    
    paginatedUsers() {
      if (!Array.isArray(this.userList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.userList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.totalUsers / this.itemsPerPage);
    },
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    async getMyDepartAllList() {
      try {      
        const response = await apiClient.post("/api/Department/admin-my-depart-all-list", {
          UserId: localStorage.getItem("userid"),
          UserType: this.$store.getters.getUsertype
        });
        
        const data = JSON.parse(response.data);
        this.departments = data.data;
      } catch (error) {
          console.error(`Error get departments: ${error}`);
      }
    },
    async getDepartUserList(selectedDepart = this.selectedDepart) {
      if (!selectedDepart) {
        console.error('Selected department is undefined or null');
        return;
      }
      
      try {
        const departmentInfo = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          Type: selectedDepart.type,
          DeptCompanyId: selectedDepart.deptCompanyId,
          DeptWorkplaceId: selectedDepart.deptWorkplaceId,
          DeptId: selectedDepart.deptId,          
          OrderType: this.OrderType,
        };
        
        const response = await apiClient.post(
          "/api/Department/admin-depart-user-list",
          departmentInfo
        );
        
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.userList = Array.isArray(data.data) ? data.data : [];
          this.totalUsers = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error(`Error get departUserList: ${error}`);
      }
    },
    selectDepartment(department) {
      this.selectedDepart = department;

      if (department) {
        this.getDepartUserList(department);
      } else {
        console.error("No department object received");
      }
    },
    editItem({ deptId, deptName }) {
      const dept = this.findDepartment(this.departments, deptId);
      if (dept) {
        dept.deptName = deptName;
        this.departments = [...this.departments];
      }
    },
    deleteItem(department) {
      const recursiveDelete = (departments, departmentToDelete) => {
        const index = departments.findIndex(dep => dep.deptId === departmentToDelete.deptId);
        if (index !== -1) {
          departments.splice(index, 1);
        } else {
          departments.forEach(dep => {
            if (dep.children && dep.children.length > 0) {
              recursiveDelete(dep.children, departmentToDelete);
            }
          });
        }
      };

      recursiveDelete(this.departments, department);
    },
    async addItem(newDepartment) {
        let parentDepartment = null;

        if (newDepartment.parentDeptId === 0) {
            parentDepartment = this.findTopDepartment(this.departments, newDepartment.deptCompanyId, newDepartment.deptWorkplaceId);
        } else {
            parentDepartment = this.findEtcDepartment(this.departments, newDepartment.parentDeptId, newDepartment.deptCompanyId, newDepartment.deptWorkplaceId);
        }

        const newItem = {
            deptId: newDepartment.deptId ?? 0,
            deptName: '새 항목',
            parentDeptId: newDepartment.parentDeptId ?? 0,
            deptCompanyId: newDepartment.deptCompanyId,
            deptWorkplaceId: newDepartment.deptWorkplaceId,
            level: parentDepartment ? parentDepartment.level + 1 : newDepartment.level + 1,
            type: 'D',
            children: []
        };

        if (parentDepartment) {
            parentDepartment.children.push(newItem);
        } else {
            this.departments.push(newItem);
        }
    },
    findDepartment(departments, deptId, rootNode = null) {
      if (deptId === 0 && rootNode) {
          return rootNode;
      }
      
      for (const dep of departments) {
          if (dep.deptId === deptId) {
              return dep;
          }
          
          if (dep.children && dep.children.length > 0) {
              const found = this.findDepartment(dep.children, deptId, dep);
              if (found) {
                  return found;
              }
          }
      }

      return null;
    },
    findTopDepartment(departments, deptCompanyId, deptWorkplaceId) {
        for (const dep of departments) {
            if (dep.deptCompanyId === deptCompanyId && dep.deptWorkplaceId === deptWorkplaceId && dep.type === 'W') {
                return dep;
            }

            if (dep.children && dep.children.length > 0) {
                const found = this.findTopDepartment(dep.children, deptCompanyId, deptWorkplaceId);
                if (found) {
                    return found;
                }
            }
        }

        return null;
    },
    findEtcDepartment(departments, parentDeptId, deptCompanyId, deptWorkplaceId) {
        for (const dep of departments) {
            if (dep.deptId === parentDeptId && dep.deptCompanyId === deptCompanyId && dep.deptWorkplaceId === deptWorkplaceId) {
                return dep;
            }

            if (dep.children && dep.children.length > 0) {
                const found = this.findEtcDepartment(dep.children, parentDeptId, deptCompanyId, deptWorkplaceId);
                if (found) {
                    return found;
                }
            }
        }

        return null;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.userList = []; // 페이지 변경 시 목록 초기화
        this.getDepartUserList(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.userList = []; // 페이지 변경 시 목록 초기화
        this.getDepartUserList(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.userList = []; // 페이지 변경 시 목록 초기화
      this.getDepartUserList(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.userList = []; // 페이지 변경 시 목록 초기화
      this.getDepartUserList(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.userList = []; // 페이지 변경 시 목록 초기화
      this.getDepartUserList();
    },    
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.userList = []; // 페이지 변경 시 목록 초기화
      this.getDepartUserList();
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.getDepartUserList();
    },
  }
};
</script>

<style scoped>
</style>
