<template>
  <header class="header">
    <div class="header_top">
      <div class="header_topbox">
        <div class="header_top_column">
          <a href="https://mizeinc.co.kr" target="_blank">MIZE INC.</a>
        </div>
        <div class="header_top_column">
          <!-- Vue 데이터 바인딩해야 함 -->
          <span class="top_name">{{ username }} 님 </span>
          <router-link to="/myinfo/management">내 정보</router-link>
          <a href="#" @click.prevent="logout">로그아웃</a>
        </div>
      </div>
    </div>
    <nav class="header_bottom">
      <div class="header_bottombox">
        <div class="logo">
          <img src="@/images/logo_w.png" />
        </div>
        <div class="menu">
          <a
            href="#"
            :class="{ on: isActive('/reservation/management') }"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
            @click.prevent="navigateToReservationManagement"
          >
            <img src="@/images/topic_thum_69.png" alt="" />예약관리
          </a>
          <a
            href="#"
            :class="{ on: isActive('/product/management') }"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
            @click.prevent="navigateToOtherManagement('/product/management')"
          >
            <img src="@/images/topic_thum_65.png" alt="" />상품관리
          </a>
          <!-- 인증관리 메뉴 하게 되면 주석된 부분으로 바꿔야 함 -->
          <!-- <router-link
            to="/certification/management"
            :class="{ on: isActive('/certification/management') }"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
            @click.prevent="showComingSoonMessage"
          > -->
          <a
            href="#"
            :class="{ on: isActive('/certification/management') }"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
            @click.prevent="showComingSoonMessage"
          >
            <img src="@/images/topic_thum_57.png" alt="" />인증관리
          </a>          
          <a
            href="#"
            :class="{ on: isActive('/employee/management') }"
            @click.prevent="navigateToOtherManagement('/employee/management')"
          >
            <img src="@/images/topic_thum_75.png" alt="" />직원관리
          </a>          
          <a
            href="#"
            :class="{ on: isActive('/workplace/management') }"
            v-if="userType === 'S' || userType === 'C'"
            @click.prevent="navigateToOtherManagement('/workplace/management')"
          >
            <img src="@/images/topic_thum_73.png" alt="" />사업장관리
          </a>  
          <a
            href="#"
            :class="{ on: isActive('/company/management') }"
            v-if="userType === 'S'"
            @click.prevent="navigateToOtherManagement('/company/management')"
          >
            <img src="@/images/topic_thum_55.png" alt="" />회사관리
          </a>
          <a
            href="#"
            :class="{ on: isActive('/setting/management') }"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
            @click.prevent="navigateToOtherManagement('/setting/management')"
          >
            <img src="@/images/topic_thum_67.png" alt="" />설정
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "HeaderMenu",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      menuList: [], // 상품 카테고리 데이터를 저장
      reservationRoute: "", // 예약 관리로 이동할 동적 경로
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    selectedMenu() {
      return this.$store.getters.getSelectedMenu; // Vuex 상태에서 현재 선택된 메뉴를 가져옴
    },
  },
  async mounted() {
    this.checkAuth();

    const token_userid = localStorage.getItem("userid");

    if (token_userid) {
      const user = {
        UserId: token_userid,
      };

      // API 호출
      if (user.UserId) {
        try {
          const response = await apiClient.post(
            "/api/User/admin-user-my-info",
            user
          );

          if (response.status === 200) {
            const userName = response.data.user.AdminName;
            const usertype = response.data.user.UserType;

            this.$store.dispatch("updateUserName", userName); // Vuex 스토어와 localStorage에 저장
            this.$store.dispatch("updateUserType", usertype); // Vuex 스토어와 localStorage에 저장
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    } else {
      this.$router.push({ path: "/" });
    }
    this.fetchProductCategoryData();
  },
  methods: {
    showComingSoonMessage() {
      this.toast.error("아직 준비 중입니다.", {
        position: "top-center", // 메시지 위치
        timeout: 1500, // 3초 동안 표시
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: true, // 진행 표시줄 숨기기
        closeButton: false, // X 버튼 숨기기
      });
    },
    checkAuth() {
      const token_userid = localStorage.getItem("userid");

      if (!token_userid) {
        this.$router.push({ path: "/" });
      } else if (this.$route.path === "/") {
        this.$router.push({ path: "/myinfo/management" });
      }
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        this.toast.success("로그아웃 되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        localStorage.clear();
        this.$store.dispatch("updateUserName", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$store.dispatch("updateUserType", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$router.push({ path: "/" });
      }
    },
    isActive(path) {
      return this.selectedMenu === path; // 현재 선택된 메뉴와 경로를 비교
    },
    getRoute(name) {  
      const routes = {
        회의실: "/reservation/meetingroom/status/list",
        자율좌석: "/reservation/freeseat/status/list",
        공용시설: "/reservation/publicroom/status/list",
      };
      return routes[name] || "/";
    },
    async fetchProductCategoryData() {
      try {
        const response = await apiClient.post(
          "/api/Product/product-category-info",
          {
            LoginId: localStorage.getItem("userid"),
            SearchUserType: localStorage.getItem("usertype"),
          }
        );
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);

          if (Array.isArray(data.data) && data.data.length > 0) {
            this.menuList = data.data; // 받아온 상품 카테고리 목록을 저장

            // 첫 번째 상품 카테고리의 경로를 reservationRoute로 설정
            const firstMenu = this.menuList[0]; // 첫 번째 메뉴
            this.reservationRoute = this.getRoute(
              firstMenu.productTypeName.trim()
            ); // 첫 번째 카테고리 경로로 설정
          }
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      }
    },
    navigateToReservationManagement() {
      if (this.reservationRoute) {
        this.$router.replace({ path: '/temp-route' }).then(() => {
          this.$router.replace(this.reservationRoute);
        });
      } else {
        this.$router.push("/reservation/management"); // 기본 경로로 이동
      }
    },
    navigateToOtherManagement(path) {
      this.$router.replace({ path: '/temp-route' }).then(() => {
        this.$router.replace(path);
      });
    },
  },
};
</script>

<style scoped></style>
