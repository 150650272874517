<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">좌석유형 목록</div>
        <div class="description">
          좌석 유형을 조회하고 관리할 수 있습니다. <br />
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="chgSelectCompany"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="chgSelectWorkplace"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>좌석유형명 검색</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="pop_text"
                        v-model="searchKey"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span class="accent_color">{{ this.totalSeatTypes }}</span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5"></option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>개씩</span>
            </div>
            <button type="button" @click="showModal('add')">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col />
                  <col style="width: 20%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                        :disabled="!checkSelectAll"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('company_name')" class="sortable">
                      <a href="###">회사명</a>
                      <img v-if="orderType === 'company_name asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'company_name desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplace_name')" class="sortable">
                      <a href="###">사업장명</a>
                      <img v-if="orderType === 'workplace_name asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'workplace_name desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('seat_type_name')" class="sortable">
                      <a href="###">좌석유형명</a>                      
                      <img v-if="orderType === 'seat_type_name asc'" src="@/images/up_icon_s.png" />
                      <img v-if="orderType === 'seat_type_name desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody v-if="seatTypeList.length > 0">
                  <tr
                    v-for="seatType in paginatedSeatType"
                    :key="seatType.seatTypeId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(seatType.seatTypeId)"
                        :checked="selectedTypeSeats.includes(seatType.seatTypeId)"
                        :disabled="seatType.createdUserId !== loginUserId"
                      />
                    </td>
                    <td class="no_mobile">{{ seatType.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ seatType.companyName }}</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">사업장명 : </span>
                      <span>{{ seatType.workplaceName }}</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">좌석유형명 : </span>
                      <span>{{ seatType.seatTypeName }}</span>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">관리 : </span>
                      <div class="btns" v-if="seatType.createdUserId === loginUserId">
                        <button @click="showModal('edit', seatType.companyId, seatType.workplaceId, seatType.seatTypeId, seatType.seatTypeName)">
                          수정
                        </button>                        
                        <button @click="delSeatType(seatType.seatTypeId)">
                          삭제
                        </button>
                      </div>
                    </td>
                  </tr>                  
                </tbody>
                <tbody v-else>
                  <tr class="no_data">
                    <td colspan="5">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <button type="button" @click.prevent="delSelectedSeatType">
                삭제
              </button>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- 좌석유형 등록&수정 modal -->
  <div id="Modal" class="d_pop" v-if="isModalVisible">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">좌석유형 {{ modalType }}</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr v-if="userType === 'S'">
                <th>회사</th>
                <td>
                  <div class="inner_td">
                    <select
                        class="w_200"
                        v-model="selectedCompanyModal"
                        :disabled="modalType === '수정'"
                        @change="chgSelectCompanyModal"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companiesModal"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                  </div>
                </td>
              </tr>
              <tr v-if="userType === 'S' || userType === 'C'">
                <th>사업장</th>
                <td>
                  <div class="inner_td">
                    <select
                      class="w_200"
                      v-model="selectedWorkplaceModal"
                      :disabled="modalType === '수정'"
                      @change="chgSelectWorkplaceModal"
                    >
                      <option value="0">전체보기</option>
                      <option
                        v-if="selectedCompanyModal === '0' && userType !== 'C'"
                        disabled
                      >
                        회사를 선택하세요
                      </option>
                      <option
                        v-for="workplace in workplacesModal"
                        :key="workplace.workplaceId"
                        :value="workplace.workplaceId"
                      >
                        {{ workplace.workplaceName }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>좌석유형명</th>
                <td>                  
                  <div class="inner_td">
                    <input
                        type="text"
                        class="pop_text"
                        v-model="seatTypeNameModal"
                        ref="seatTypeNameModal"
                        placeholder="좌석유형명을 입력해주세요."
                        @keyup.enter="handleSearchKeyEnter"
                      />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>        
        <div class="btn_bottom_a">
          <a href="###" class="on" v-if="modalType === '등록'" @click="addSeatType">등록</a>
          <a href="###" class="on" v-else-if="modalType === '수정'" @click="editSeatType">수정</a>
          <a href="###" @click="hideModal">취소</a>
        </div>
      </div>
    </div>
  </div>
  <!-- //좌석유형 등록&수정 modal -->
</template>

<script>
import apiClient from "@/apiClient";
import { useToast } from "vue-toastification";

export default {
  name: "SettingFreeSeatTypeList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {      
      seatTypeList: [],
      companies: [],
      workplaces: [],
      selectedTypeSeats: [],
      selectedCompany: "0",
      selectedWorkplace: "0",
      companiesModal: [],
      workplacesModal: [],
      selectedCompanyModal: "0",
      selectedWorkplaceModal: "0",
      selectedTypeSeatModal: "",
      totalSeatTypes: 0,
      currentPage: 1,
      itemsPerPage: 10,
      pageGroupSize: 5,
      searchKey: "",
      orderType: "",
      allSelected: false,
      loginUserId: "",
      modalType: "등록",
      isModalVisible: false,
      isChkFreeseatName: false,
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },    
    paginatedSeatType() {
      if (!Array.isArray(this.seatTypeList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.seatTypeList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.totalSeatTypes / this.itemsPerPage);
    },
    pageGroup() {
      const start = Math.floor((this.currentPage - 1) / this.pageGroupSize) * this.pageGroupSize + 1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    checkSelectAll() {
      return this.seatTypeList.some(seatType => seatType.createdUserId === this.loginUserId);
    }
  },
  methods: { 
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedTypeSeats = this.seatTypeList
          .filter(seat => seat.createdUserId === this.loginUserId)
          .map(seat => seat.seatTypeId);
      } else {
        this.selectedTypeSeats = [];
      }
    },
    toggleSelection(seatTypeId) {
      const selectedSeat = this.seatTypeList.find(
        (seat) => seat.seatTypeId === seatTypeId
      );

      if (!selectedSeat || selectedSeat.createdUserId !== this.loginUserId) {
        return;
      }
      
      const isSelected = this.selectedTypeSeats.includes(seatTypeId);

      if (isSelected) {
        const index = this.selectedTypeSeats.indexOf(seatTypeId);
        if (index > -1) {
          this.selectedTypeSeats.splice(index, 1);
        }
      } else {
        this.selectedTypeSeats.push(seatTypeId);
      }
    },
    async chgSelectCompany() {
      this.currentPage = 1;
      this.selectedWorkplace = "0";
      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany);
      }
      this.getSeatTypeList();
    },
    async chgSelectWorkplace() {
      this.currentPage = 1;
      this.getSeatTypeList();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId,
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async chgSelectCompanyModal() {
      this.currentPage = 1;
      this.selectedWorkplaceModal = "0";
      if (this.selectedCompanyModal === "0") {
        this.workplacesModal = [];
      } else {
        await this.fetchWorkplacesModal(this.selectedCompanyModal);
      }
    },
    async chgSelectWorkplaceModal() {
      this.currentPage = 1;
    },
    async fetchCompaniesModal() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companiesModal = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching companiesModal:", error);
      }
    },
    async fetchWorkplacesModal(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A",
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId,
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.workplacesModal = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching workplacesModal:", error);
      }
    },
    async getSeatTypeList() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";
        
        if (this.userType === "S") {
          v_companyId = this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace;
        } else if (this.userType === "C") {
          v_companyId = this.companyId;
          v_workplaceId = this.selectedWorkplace === "0" ? "0" : this.selectedWorkplace;
        } else if (this.userType === "W") {
          v_companyId = this.selectedCompany;
          v_workplaceId = this.selectedWorkplace;
        }
        
        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.orderType,
          CompanyId: v_companyId,
          WorkplaceId: v_workplaceId,
          SearchKey: this.searchKey,
        };
        
        const response = await apiClient.post(
          "/api/Freeseat/all-freeseat-type-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.seatTypeList = Array.isArray(data.data) ? data.data : [];
          this.totalSeatTypes = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching seatTypeList data:", error);
      }
    },
    async checkInputValues() {
      if (this.selectedCompanyModal === "0" || this.selectedWorkplaceModal === "0") {
		    this.toast.error("회사 및 사업장을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.isModalVisible = true;
        return;
      }
      if (!this.seatTypeNameModal) {
		    this.toast.error("좌석유형명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
		
        this.$refs.seatTypeNameModal.focus();
        return false;
      }
      if(!await this.checkFreeseatName()) {
        this.toast.error("이미 등록된 좌석유형명입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        
        return false;
      }
      
      return true;
    },
    async checkFreeseatName() {
      try {
        const response = await apiClient.post(
          "/api/Freeseat/freeseat-type-name-check",
          {
            CompanyId: this.selectedCompanyModal,
            WorkplaceId: this.selectedWorkplaceModal,
            SeatTypeName: this.seatTypeNameModal,
          }
        );

        if (response.status === 200) {
          if (response.data.message == "F") {
            return false;
          }
          else {
            return true;
          }
        }
      } catch (error) {
          console.error(
            "Error checking freeseat name:",
            error
          );
          
          return false;
      }
    },
    async addSeatType() {
      if (await this.checkInputValues()) {
        try {
          const response = await apiClient.post("/api/Freeseat/freeseat-type-add", {
            CompanyId: this.selectedCompanyModal,
            WorkplaceId: this.selectedWorkplaceModal,
            SeatTypeName: this.seatTypeNameModal,
            LoginUserId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            this.toast.success("좌석유형이 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            this.hideModal();
            this.getSeatTypeList();
          }
        } catch (error) {
          console.error("Error saving product:", error);
          this.toast.error("좌석유형 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
    
        }
      }
    },
    async editSeatType() {
      if (await this.checkFreeseatName()) {
        try {
          const response = await apiClient.post("/api/Freeseat/freeseat-type-edit", {
            SeatTypeId: this.selectedTypeSeatModal,
            SeatTypeName: this.seatTypeNameModal,
            LoginUserId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            this.toast.success("저장되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            this.hideModal();
            this.getSeatTypeList();
          }
        } catch (error) {
          console.error("Error saving freeseat type name:", error);
          this.toast.error("좌석유형명 저장 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
      else {
        this.toast.error("이미 등록된 좌석유형명입니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    async delSeatType(seatTypeId) {
      if (this.selectedTypeSeats.length > 1) {
        this.toast.error("선택된 데이터가 여러 개 있습니다. 하나만 삭제 가능합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return;
      }

      if (confirm("삭제하시겠습니까?")) {
        try {
          const response = await apiClient.post("/api/Freeseat/freeseat-type-del", {
            SeatTypeId: seatTypeId,
            LoginUserId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            this.toast.success("좌석유형이 삭제되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            this.hideModal();
            this.getSeatTypeList();
          }
        } catch (error) {
          console.error("Error deleting freeseat type:", error);
          this.toast.error("좌석유형 삭제 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async delSelectedSeatType() {
      if (confirm("삭제하시겠습니까?")) {
        if (this.selectedTypeSeats.length === 0) {
          this.toast.error("삭제를 원하시는 좌석유형 리스트를 선택해주세요", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });

          return;
        }

        try {
          const promises = this.selectedTypeSeats.map(async (seatTypeId) => {
            return apiClient.post("/api/Freeseat/freeseat-type-del", {
              SeatTypeId: seatTypeId,
              LoginUserId: localStorage.getItem("userid"),
            });
          });

          const responses = await Promise.all(promises);

          responses.forEach((response, index) => {
            if (response.status !== 200) {
              console.error(
                `Failed to seattype-del for ID ${this.selectedTypeSeats[index]}`
              );
            }
          });

          this.toast.success("좌석유형이 삭제되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          
          this.selectedTypeSeats = [];
          this.allSelected = false;        
          this.getSeatTypeList();
        } catch (error) {
          console.error("Error seattype-del:", error);
          this.toast.error("좌석유형 삭제 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
        }
      }
    },
    showModal(type, companyId, workplaceId, seatTypeId, seatTypeName) {
      this.isModalVisible = true;
      this.modalType = type === "add" ? "등록" : "수정";
      this.fetchCompaniesModal();

      if (type === "edit") {
        if (this.selectedTypeSeats.length > 1) {
          this.toast.error("선택된 데이터가 여러 개 있습니다. 하나만 수정 가능합니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });

          this.isModalVisible = false;
        }
        else {
          this.fetchWorkplacesModal();
          this.selectedCompanyModal = companyId;
          this.selectedWorkplaceModal = workplaceId;
          this.seatTypeNameModal = seatTypeName;
          this.selectedTypeSeatModal = seatTypeId;
        }
      }
    },
    hideModal() {
      this.isModalVisible = false;
      this.initModal();
    },
    initModal() {
      this.selectedCompanyModal = "0";
      this.selectedWorkplaceModal = "0";
      this.seatTypeNameModal = "";
      this.workplacesModal=[];
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.seatTypeList = [];
        this.getSeatTypeList();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.seatTypeList = [];
        this.getSeatTypeList();
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.seatTypeList = [];
      this.getSeatTypeList();
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.seatTypeList = [];
      this.getSeatTypeList();
    },
    goToPage(page) {
      this.currentPage = page;
      this.seatTypeList = [];
      this.getSeatTypeList();
    },
    handleItemsPerPageChange() {
      this.currentPage = 1;
      this.seatTypeList = [];
      this.getSeatTypeList();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1;
      this.seatTypeList = [];
      this.getSeatTypeList();
    }, 
    sort(field) {
      if (this.orderType === `${field} asc`) {
        this.orderType = `${field} desc`;
      } else {
        this.orderType = `${field} asc`;
      }
      this.getSeatTypeList();
    },
  },
  mounted() {
    this.loginUserId = localStorage.getItem("userid");
    if (this.userType === "C") {
      this.companyId = localStorage.getItem("companyid");
      this.fetchWorkplaces(this.companyId);
      this.getSeatTypeList();
    } else if (this.userType === "W") {
      this.companyId =this.selectedCompany =  localStorage.getItem("companyid");
      this.workplaceId = this.selectedWorkplace =  localStorage.getItem("workplaceid");
      this.getSeatTypeList();
    } else {
      this.fetchCompanies();
      this.getSeatTypeList();
    }
  }
};
</script>

<style scoped></style>
