<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">자율좌석 목록</div>
        <div class="description">자율좌석을 조회하고 관리할 수 있습니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>진열상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchUseStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchUseStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        진열
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchUseStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        미진열
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="상품명을 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalFreeseats }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
            <button type="button" @click="goToFreeSeatAdd">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="freeseatList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col />
                  <col style="width: 16%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img
                        v-if="OrderType === 'companyName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'companyName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img
                        v-if="OrderType === 'workplaceName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'workplaceName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('freeSeatName')" class="sortable">
                      <a>구역명</a>
                      <img
                        v-if="OrderType === 'freeSeatName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'freeSeatName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th>운영시간</th>
                    <th>진열상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="freeseat in paginatedFreeseats"
                    :key="freeseat.id"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(freeseat.id)"
                        :checked="selectedFreeSeats.includes(freeseat.id)"
                      />
                    </td>
                    <td class="no_mobile">{{ freeseat.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ freeseat.companyName }}</span>
                    </td>

                    <td class="seat o4r">
                      <span class="only_mobile">사업장명 : </span>
                      <span>{{ freeseat.workplaceName }}</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <router-link
                          :to="{
                            name: 'ProductFreeseatView',
                            params: { id: freeseat.id },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ freeseat.freeSeatName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="num o5 o_br">
                      <span class="only_mobile">운영시간 : </span
                      ><span
                        >{{ freeseat.startDtm }} ~ {{ freeseat.endDtm }}</span
                      >
                    </td>
                    <td class="center_td o_stat">
                      <div
                        class="stat"
                        :class="{
                          back_green: freeseat.activeStatus === 'Y',
                          back_red: freeseat.activeStatus === 'N',
                        }"
                      >
                        <span v-if="freeseat.activeStatus === 'Y'">진열</span>
                        <span v-else-if="freeseat.activeStatus === 'N'"
                          >미진열</span
                        >
                      </div>
                    </td>
                    <td class="o6r o_br">
                      <div class="center_td">
                        <button type="button" @click="showReservationMessage">
                          예약조회
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col />
                  <col style="width: 16%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img
                        v-if="OrderType === 'companyName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'companyName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img
                        v-if="OrderType === 'workplaceName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'workplaceName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th @click="sort('freeSeatName')" class="sortable">
                      <a>구역명</a>
                      <img
                        v-if="OrderType === 'freeSeatName_asc'"
                        src="@/images/up_icon_s.png"
                      />
                      <img
                        v-if="OrderType === 'freeSeatName_desc'"
                        src="@/images/down_icon_s.png"
                      />
                    </th>
                    <th>운영시간</th>
                    <th>진열상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="activateSelected">진열</a>
              <a @click.prevent="deactivateSelected">미진열</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "ProductFreeseatList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
      totalFreeseats: 0, // 총 회의실 목록 수
      freeseatList: [], // 회의실 룸 목록 데이터 저장할 배열
      selectedFreeSeats: [], // 상품분류 목록의 product id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "freeseatName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchUseStatus: "A", // 진열 상태별값
    };
  },
  methods: {
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.freeseatList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.freeseatList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.freeseatList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.freeseatList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.freeseatList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.freeseatList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    showReservationMessage() {
      this.toast.error("아직 준비 중입니다.", {
        position: "top-center", // 메시지 위치
        timeout: 1500, // 3초 동안 표시
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: true, // 진행 표시줄 숨기기
        closeButton: false, // X 버튼 숨기기
      });
    },
    updateStatus(status) {
      this.SearchUseStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.freeseatList = []; // 회의실 룸 목록 초기화
      this.fetchData();
    },
    goToFreeSeatAdd() {
      this.$router.push({ path: "/product/freeseat/add" });
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    deleteSelected() {
      if (this.selectedFreeSeats.length === 0) {
        this.toast.error("원하시는 삭제 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 항목을 삭제하시겠습니까?")) {
        this.DeleteFreeSeat(this.selectedFreeSeats);
      }
    },
    async DeleteFreeSeat(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/FreeSeat/freeseat-delete", {
            Id: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete freeseat for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedFreeSeats = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting freeseat:", error);
      }
    },
    activateSelected() {
      if (this.selectedFreeSeats.length === 0) {
        this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      const alreadyActive = this.selectedFreeSeats.some((id) => {
        const freeseat = this.freeseatList.find((item) => item.id === id);
        return freeseat && freeseat.activeStatus === "Y";
      });

      if (alreadyActive) {
        this.toast.error("이미 진열 상태인 항목이 존재합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      if (confirm("선택된 항목의 상태를 진열로 변경하시겠습니까?")) {
        this.updateSeatStatus(this.selectedFreeSeats, "Y");
      }
    },
    deactivateSelected() {
      if (this.selectedFreeSeats.length === 0) {
        this.toast.error("선택된 항목이 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      const alreadyInactive = this.selectedFreeSeats.some((id) => {
        const freeseat = this.freeseatList.find((item) => item.id === id);
        return freeseat && freeseat.activeStatus === "N";
      });

      if (alreadyInactive) {
        this.toast.error("이미 미진열 상태인 항목이 존재합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      if (confirm("선택된 항목의 상태를 미진열로 변경하시겠습니까?")) {
        this.updateSeatStatus(this.selectedFreeSeats, "N");
      }
    },
    async updateSeatStatus(selectedIds, status) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/FreeSeat/freeseat-active-update", {
            Id: id,
            ActiveStatus: status,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status === 200) {
            const id = selectedIds[index];
            // 선택된 회사들의 상태 업데이트
            this.freeseatList.forEach((freeseat) => {
              if (freeseat.id === id) {
                freeseat.activeStatus = status;
              }
            });
          } else {
            console.error(
              `Failed to update freeseat status for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedFreeSeats = [];
        this.allSelected = false; // thead 체크박스 해제
      } catch (error) {
        console.error("Error updating freeseat status:", error);
      }
    },
    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedFreeSeats = this.freeseatList.map(
          (freeseat) => freeseat.id
        );
      } else {
        this.selectedFreeSeats = [];
      }
    },
    toggleSelection(id) {
      const index = this.selectedFreeSeats.indexOf(id);

      if (index > -1) {
        this.selectedFreeSeats.splice(index, 1);
      } else {
        this.selectedFreeSeats.push(id); // 기존 코드를 이 줄로 수정
      }
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.freeseatList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = localStorage.getItem("workplaceid");
        }

        console.log(v_companyId);
        console.log(v_workplaceId);

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchUseStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        // console.log("★★★★----" + searchParam.Page);
        // console.log("★★★★----" + searchParam.PageSize);
        // console.log("★★★★----" + searchParam.OrderType);
        // console.log("★★★★----" + searchParam.SearchActiveStatus);
        // console.log("★★★★----" + searchParam.SearchType);
        // console.log("★★★★----" + searchParam.CompanyId);
        // console.log("★★★★----" + searchParam.WorkplaceId);

        const response = await apiClient.post(
          "/api/FreeSeat/all-freeseat-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.freeseatList = Array.isArray(data.data) ? data.data : [];
          this.totalFreeseats = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching freeseat list data:", error);
      }
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedFreeseats() {
      if (!Array.isArray(this.freeseatList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.freeseatList);
      console.log(
        "Paginated freeseatList: ",
        this.freeseatList.slice(start, end)
      );
      return this.freeseatList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalFreeseats / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  mounted() {
    if (this.userType === "C") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      const workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");

      // 회사 및 사업장 정보를 설정
      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      // 데이터를 바로 가져옴
      this.fetchData();
    } else {
      this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
