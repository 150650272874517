<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회의실 예약 목록</div>
        <div class="description">
          회의실 예약 내역을 조회하고 관리할 수 있습니다.
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'S'">
                  <th>회사별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                          <!-- {{ company.id }} -->
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'S' || userType === 'C'">
                  <th>사업장별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedWorkplace"
                        @change="handleWorkplaceChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-if="selectedCompany === '0' && userType !== 'C'"
                          disabled
                        >
                          회사를 선택하세요
                        </option>
                        <option
                          v-for="workplace in workplaces"
                          :key="workplace.workplaceId"
                          :value="workplace.workplaceId"
                        >
                          {{ workplace.workplaceName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr style="display: none">
                  <th>날짜</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        id="startDate"
                        class="w_150 hasDatepicker"
                        autocomplete="off"
                      />
                      <button type="button" class="ui-datepicker-trigger">
                        <img src="@/images/calendar.png" />
                      </button>
                      <input
                        type="text"
                        id="endDate"
                        class="w_150 hasDatepicker"
                        autocomplete="off"
                      />
                      <button type="button" class="ui-datepicker-trigger">
                        <img src="@/images/calendar.png" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <!-- 전체보기 ('A') / 
                       이용가능 ('') / 
                       사용중 ('I') /
                       만료 ('O') / 
                       예약취소 ('C') / 
                       노쇼 ('N') / 
                       양도 ('M') 
                  -->
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === '' }"
                        @click="updateStatus('')"
                      >
                        이용가능
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'I' }"
                        @click="updateStatus('I')"
                      >
                        사용중
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'O' }"
                        @click="updateStatus('O')"
                      >
                        만료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'C' }"
                        @click="updateStatus('C')"
                      >
                        예약취소
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        노쇼
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'M' }"
                        @click="updateStatus('M')"
                      >
                        양도
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="meetingroomName">회의실명</option>
                        <option value="reservationName">예약자명</option>
                        <option value="reservationPhone">휴대폰번호</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        @keyup.enter="handleSearchKeyEnter"
                        placeholder="검색어를 입력해주세요"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalReservations }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>명씩</span>
            </div>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="reservationList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 10%" />
                  <col style="width: 13%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 12%" />
                  <col style="width: 10%" />
                  <col style="width: 11%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th @click="sort('resDate')" class="sortable">
                      <a>예약일</a>
                      <img v-if="OrderType === 'resDate_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'resDate_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('meetingRoomName')" class="sortable">
                      <a>회의실명</a>
                      <img v-if="OrderType === 'meetingRoomName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'meetingRoomName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('resUserName')" class="sortable">
                      <a>예약자명</a>
                      <img v-if="OrderType === 'resUserName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'resUserName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>휴대폰번호</th>
                    <th>상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="reservation in paginatedReservations"
                    :key="reservation.resId"
                    class="no_move"
                  >
                    <td class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(reservation.resId)"
                        :checked="
                          selectedReservations.includes(reservation.resId)
                        "
                      />
                    </td>
                    <td class="name o8 o_br">
                      <span class="only_mobile">예약일 : </span>
                      <span class="num">{{ reservation.reservationDate }}</span>
                    </td>
                    <td class="seat o4">
                      <span class="only_mobile">회사명 : </span>
                      <span>{{ reservation.companyName }}</span>
                    </td>
                    <td class="seat o5r">
                      <span class="only_mobile">사업장명 : </span>
                      <span>{{ reservation.workplaceName }}</span>
                    </td>
                    <td class="detail_page_a o3 o_title">
                      <div class="center_td">
                        <router-link
                          :to="{
                            name: 'ResrvationMeetingroomStatusview',
                            params: { id: reservation.resId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ reservation.meetingRoomName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="count o6">
                      <span class="only_mobile">예약자명 : </span>
                      <span>{{ reservation.reservationUserName }}</span>
                    </td>
                    <td class="num phone o7r">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>{{ reservation.reservationUserPhone }}</span>
                    </td>
                    <td class="center_td o_stat">
                      <div :class="getStatusClass(reservation.activeStatus)">
                        <span
                          >{{ getStatusText(reservation.activeStatus) }}
                        </span>
                      </div>
                    </td>
                    <td class="o9r o_br">
                      <div class="center_td">
                        <button
                          type="button"
                          v-if="reservation.activeStatus === ''"
                          @click="cancelReservation(reservation.resId)"
                        >
                          예약취소
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="9">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 10%" />
                  <col style="width: 13%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 12%" />
                  <col style="width: 10%" />
                  <col style="width: 11%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th @click="sort('resDate')" class="sortable">
                      <a>예약일</a>
                      <img v-if="OrderType === 'resDate_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'resDate_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('companyName')" class="sortable">
                      <a>회사명</a>
                      <img v-if="OrderType === 'companyName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'companyName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('workplaceName')" class="sortable">
                      <a>사업장명</a>
                      <img v-if="OrderType === 'workplaceName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'workplaceName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('meetingRoomName')" class="sortable">
                      <a>회의실명</a>
                      <img v-if="OrderType === 'meetingRoomName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'meetingRoomName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th @click="sort('resUserName')" class="sortable">
                      <a>예약자명</a>
                      <img v-if="OrderType === 'resUserName_asc'" src="@/images/up_icon_s.png" />
                      <img v-if="OrderType === 'resUserName_desc'" src="@/images/down_icon_s.png" />
                    </th>
                    <th>휴대폰번호</th>
                    <th>상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="9">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <!-- 회의실 예약 목록에서 왜 삭제가 필요한거지.. -->
              <a style="display: none">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification"; 

export default {
  name: "ResrvationMeetingroomStatusView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      workplaces: [], // API 에서 가져온 사업장 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      selectedWorkplace: "0", // 기본값 설정
      totalReservations: 0, // 총 회의실 예약 목록 수
      reservationList: [], // 회의실 에약 목록 데이터 저장할 배열
      selectedReservations: [], // 상품분류 목록의 product id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "meetingroomName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
    };
  },
  methods: {
    async cancelReservation(resId) {
      const confirmCancel = confirm("예약을 취소하시겠습니까?");
      if (confirmCancel) {
        try {
          // 여기에 예약 취소 API 호출
          const response = await apiClient.post(
            "/api/MeetingRoomReservation/meetingroom-reservation-cancel",
            {
              ResId: resId, // 취소할 예약 ID
              LoginId: localStorage.getItem("userid"), // 'C'는 예약취소 상태를 의미
            }
          );

          if (response.status === 200) {
            this.toast.success("예약이 성공적으로 취소되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.fetchData(); // 데이터 갱신
          } else {	
            this.toast.error("예약 취소 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        } catch (error) {
          console.error("예약 취소 중 오류가 발생했습니다:", error);
          this.toast.error("예약 취소 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.reservationList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    // 상태에 따른 문구 반환
    getStatusText(res_status) {
      switch (res_status) {
        case "":
          return "이용가능";
        case "I":
          return "사용중";
        case "O":
          return "만료";
        case "C":
          return "예약취소";
        case "N":
          return "노쇼";
        case "M":
          return "양도";
      }
    },

    // 상태에 따른 CSS 클래스 반환
    getStatusClass(res_status) {
      switch (res_status) {
        // 이용가능
        case "":
          return "stat back_green";
        // 사용중
        case "I":
          return "stat back_blue";
        // 만료
        case "O":
          return "stat back_grey";
        // 예약취소
        case "C":
          return "stat back_red";
        // 노쇼
        case "N":
          return "stat back_orange";
        // 양도
        case "M":
          return "stat back_lightgrey";
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.reservationList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.reservationList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.reservationList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.reservationList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.reservationList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.reservationList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.reservationList = []; // 회의실 룸 목록 초기화
      this.fetchData();
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchWorkplaces(companyId) {
      try {
        let v_companyId = "";

        if (this.userType === "S") {
          v_companyId = companyId;
        } else if (this.userType === "C") {
          v_companyId = this.companyId; //localStorage.getItem("companyid");
        }

        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "workplaceName_asc",
          PageSize: 100,
          Page: 1,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/Workplace/all-workplace-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);
          this.workplaces = Array.isArray(data.data) ? data.data : [];
          console.log(this.workplaces);
        }
      } catch (error) {
        console.error("Error fetching workplaces:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";
        let v_workplaceId = "";

        if (this.userType === "S") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "C") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = this.selectedWorkplace; // 선택된 사업장 ID를 저장할 변수
        } else if (this.userType === "W") {
          v_companyId = localStorage.getItem("companyid");
          v_workplaceId = localStorage.getItem("workplaceid");
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          WorkplaceId: v_workplaceId, // 전체보기 시 빈 문자열로 설정
        };

        const response = await apiClient.post(
          "/api/MeetingRoomReservation/all-meetingroom-reservation-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.reservationList = Array.isArray(data.data) ? data.data : [];
          console.log("★★★★--reservationList-!!!-" + this.reservationList);
          this.totalReservations = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error(
          "Error fetching meetingroom reservation list data:",
          error
        );
      }
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋

      if (this.selectedCompany === "0") {
        this.workplaces = [];
      } else {
        await this.fetchWorkplaces(this.selectedCompany); // 회사 선택 시 사업장 목록 갱신
      }
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async handleWorkplaceChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData();
    },
    sort(field) {
      if (this.OrderType === `${field}_asc`) {
        this.OrderType = `${field}_desc`;
      } else {
        this.OrderType = `${field}_asc`;
      }
      this.fetchData();
    },
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    companyId() {
      return this.$store.getters.getCompanyid;
    },
    workplaceId() {
      return this.$store.getters.getWorkplaceid;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedReservations() {
      if (!Array.isArray(this.reservationList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.reservationList);
      console.log(
        "Paginated reservationList: ",
        this.reservationList.slice(start, end)
      );
      return this.reservationList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalReservations / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  mounted() {
    this.SearchKey = this.$route.query.searchkey;

    if (this.userType === "C") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      this.fetchWorkplaces(companyId);
      this.fetchData();
    } else if (this.userType === "W") {
      const companyId = this.companyId; //localStorage.getItem("companyid");
      const workplaceId = this.workplaceId; //localStorage.getItem("workplaceid");

      // 회사 및 사업장 정보를 설정
      this.selectedCompany = companyId;
      this.selectedWorkplace = workplaceId;

      // 데이터를 바로 가져옴
      this.fetchData();
    } else {
      this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
      this.fetchData();
    }
  },
};
</script>

<style scoped></style>
