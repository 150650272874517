<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt="" />설정
    </div>
    <div class="left_body">
      <ul class="menu">
        <li>
          <router-link
            to="/setting/notice/list"
            :class="{ on: selectedMenu === 0 }"
             @click.prevent="handleMenuClick(0, '/setting/notice/list')"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 목록
          </router-link>
        </li>
        <!-- <li>
          <router-link
            to="/setting/notice/add"
            :class="{ on: selectedMenu === 1 }"
            @click="selectMenu(1)"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 등록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/notice/view"
            :class="{ on: selectedMenu === 2 }"
            @click="selectMenu(2)"
            v-if="userType === 'S' || userType === 'C' || userType === 'W'"
          >
            공지사항 상세보기
          </router-link>
        </li> -->
        <li>
          <router-link
            to="/setting/termsofuse/list"
            :class="{ on: selectedMenu === 3 }"
             @click.prevent="handleMenuClick(3, '/setting/termsofuse/list')"
            v-if="userType === 'S'"
          >
            이용약관 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/privacy/list"
            :class="{ on: selectedMenu === 6 }"
             @click.prevent="handleMenuClick(6, '/setting/privacy/list')"
            v-if="userType === 'S'"
          >
            개인정보처리방침 목록
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/user/join/list"
            :class="{ on: selectedMenu === 9 }"
             @click.prevent="handleMenuClick(9, '/setting/user/join/list')"
            v-if="userType === 'S'"
          >
            <!-- 회원가입항목 목록(슈퍼용) -->
            추가정보관리(슈퍼용)
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/user/join/listTwo"
            :class="{ on: selectedMenu === 10 }"
             @click.prevent="handleMenuClick(10, '/setting/user/join/listTwo')"
            v-if="userType === 'C'"
          >
            추가정보관리
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/meetingroom/access/control"
            :class="{ on: selectedMenu === 11 }"
            @click.prevent="handleMenuClick(11, '/setting/meetingroom/access/control')"
            @click="selectMenu(11)"
            v-if="userType === 'C'"
          >
            상세 출입 설정
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/etc/noshow/control"
            :class="{ on: selectedMenu === 12 }"
            @click.prevent="handleMenuClick(12, '/setting/etc/noshow/control')"
            @click="selectMenu(12)"
            v-if="userType === 'C'"
          >
            노쇼 기능 설정
          </router-link>
        </li>
        <li>
          <router-link
            to="/setting/etc/tablet/list"
            :class="{ on: selectedMenu === 13 }"
             @click.prevent="handleMenuClick(13, '/setting/etc/tablet/list')"
            v-if="userType === 'C'"
          >
            태블릿 관리
          </router-link>
        </li>
        <li v-for="(menu) in menuList" :key="menu.id">
          <router-link
            :to="getRoute(menu.productTypeName)"
            :class="{ on: selectedMenu === menu.index }"
            @click.prevent="handleMenuClick(menu.index, menu.path)"
          >
            {{ menu.productTypeName }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "Setting_Left",
  data() {
    return {
      selectedMenu: null,
      menuList: [],
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUsertype;
    },
    selectedtopMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  methods: {
    selectMenu(index, menuPath) {
      this.selectedMenu = index;
      this.$store.dispatch("updateSelectedMenu", "/setting/management"); // Vuex 상태 업데이트
    },
    updateSelectedMenu() {
      const path = this.$route.path;
      if (path === "/setting/management" || path === "/setting/notice/list") {
        this.selectedMenu = 0;
      } else if (path === "/setting/notice/add") {
        this.selectedMenu = 0;
      } else if (path === "/setting/notice/view") {
        this.selectedMenu = 0;
      } else if (path === "/setting/termsofuse/list") {
        this.selectedMenu = 3;
      } else if (path === "/setting/termsofuse/add") {
        this.selectedMenu = 3;
      } else if (path === "/setting/termsofuse/view") {
        this.selectedMenu = 3;
      } else if (path === "/setting/privacy/list") {
        this.selectedMenu = 6;
      } else if (path === "/setting/privacy/add") {
        this.selectedMenu = 6;
      } else if (path === "/setting/privacy/view") {
        this.selectedMenu = 6;
      } else if (path === "/setting/user/join/list") {
        this.selectedMenu = 9;
      } else if (path === "/setting/user/join/list2") {
        this.selectedMenu = 10;
      } else if (path === "/setting/meetingroom/access/control") {
        this.selectedMenu = 11;
      } else if (path === "/setting/etc/noshow/control") {
        this.selectedMenu = 12;
      } else if (path === "/setting/etc/tablet/list") {
        this.selectedMenu = 13;
      } else if (path === "/setting/freeseat/type/list") {
        this.selectedMenu = 14;
      } else if (path === "/setting/freeseat/type/alarm_setting") {
        this.selectedMenu = 15;
      }

      this.$store.dispatch("updateSelectedMenu", "/setting/management"); // Vuex 상태 업데이트
    },
    handleMenuClick(index, menuPath) {
      if (this.$route.path === menuPath) {
        this.$router.replace({ path: "/temp-route" }).then(() => {
          this.$router.replace(menuPath);
        });
      } else {
        this.selectMenu(index);
        this.$router.push(menuPath);
      }
    },
    getRoute(name) {
      const routes = {
        "자율좌석": "/setting/freeseat/type/list",
        "좌석알림": "/setting/freeseat/type/alarm_setting"
      };
      return routes[name] || "/";
    },
    async fetchProductCategoryData() {
      try {
        const response = await apiClient.post("/api/Product/product-category-info", {
          LoginId: localStorage.getItem("userid"),
          SearchUserType: localStorage.getItem("usertype"),
        });
        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          
          const indexMapping = {
            "자율좌석": 14,
            "좌석알림": 15,
          };

          const fetchedMenuList = (Array.isArray(data.data) ? data.data : [])
          .filter((menu) => menu.productTypeName?.trim() === "자율좌석")
          .map((menu) => ({
            ...menu,
            productTypeName: "좌석유형 목록",
            index: indexMapping[menu.productTypeName?.trim()] ?? -1,
            path: "/setting/freeseat/type/list",
          }));
          
          if (fetchedMenuList.length > 0 && (localStorage.getItem("usertype") === 'C' || localStorage.getItem("usertype") === 'W')) {
            const additionalMenu = {
              productTypeName: "좌석알림 설정",
              index: indexMapping["좌석알림"] ?? -1,
              path: "/setting/freeseat/type/alarm_setting",
            };
            fetchedMenuList.push(additionalMenu);
          }

          this.menuList = [...fetchedMenuList];
        }
      } catch (error) {
        console.error("Error fetching product category data:", error);
      }
    },
  },
  watch: {
    $route() {
      this.updateSelectedMenu();
    },
  },
  mounted() {
    this.fetchProductCategoryData();
    this.updateSelectedMenu();
  },
};
</script>

<style scoped></style>
